// This allows for changing or disabling authentication providers

import { getConfiguredProviderType, providerTypes } from './authProviderTypes';

import ADAuth from './ad/adAuth';
import ADB2CAuth from './adb2c/adb2cAuth';
import AdSpaAuth from './adSPA/adSpaAuth';
import CognitoAuth from './awsCognito/cognitoAuth';
import OAuth from './oauth2/oauth2';
import OktaAuth from './okta/oktaAuth';
import React from 'react';
import { RootState } from '../store';
import TokenAuth from './token/tokenAuth';
import { authenticate as adAuthenticate } from './ad/adAuthProvider';
import { adSpaAuthenticate } from './adSPA/adSpaAuthProvider';
import { cognitoAuthenticate } from './awsCognito/cognitoAuthProvider';
import { authenticate as oAuthAuthenticate } from './oauth2/oauth2Provider';
import { oktaAuthenticate } from './okta/oktaAuthProvider';

interface InputProps {
    children: React.ReactNode;
    store?: RootState;
    doLogout?: boolean;
    disable?: boolean;
}

const AuthProvider = (props: InputProps) => {
    const type = getConfiguredProviderType();
    // by default AD Auth is enabled but can be disabled via .env config if necessary.
    // Could choose a different provider or none as with the fragment
    if (props.disable) {
        return <>{props.children}</>;
    }
    switch (type) {
        case providerTypes.ADB2C:
            return <ADB2CAuth {...props} />;
        case providerTypes.TOKEN:
            return <TokenAuth {...props} />;
        case providerTypes.AD:
            return <ADAuth {...props} />;
        case providerTypes.AD_SPA:
            return <AdSpaAuth {...props} />;
        case providerTypes.COGNITO:
            return <CognitoAuth {...props} />;
        case providerTypes.OKTA:
            return <OktaAuth {...props} />;
        case providerTypes.OAUTH:
            return <OAuth {...props} />;
        default:
            return <ADAuth {...props} />;
    }
};

export const authenticate = (props?: any) => {
    const type = getConfiguredProviderType();
    // by default AD Auth is enabled but can be disabled via .env config if necessary.
    // Could choose a different provider or none as with the fragment
    switch (type) {
        case providerTypes.AD:
            return adAuthenticate(props);
        case providerTypes.AD_SPA:
            return adSpaAuthenticate(props);
        case providerTypes.COGNITO:
            return cognitoAuthenticate(props);
        case providerTypes.OKTA:
            return oktaAuthenticate(props);
        case providerTypes.OAUTH:
            return oAuthAuthenticate(props);
        default:
            return adAuthenticate(props);
    }
};

export default AuthProvider;
