/**
 * * Case Stage
 * @use ASSIGN_PARSE
 * @use OBLIGATION_VERIFICATION
 * @use AU_MAPPING
 * @use CONTROL_MAPPING
 *
 * * Case Status
 * @use NEW
 * @use PROCESSING
 * @use IN_PROGRESS
 * @use IN_REVIEW
 * @use CANCELLED
 * @use CLOSED
 *
 * * Case Type
 * @use PROCESS
 * @use REGULATORY
 * @use PROCEDURE
 *
 * * Document Type
 * @use CFR
 * @use ASIC
 * @use GENERIC
 *
 * * User Role
 * @use ANALYST
 * @use REVIEWER
 *
 * * Approval Status
 * @use APPROVED
 * @use REJECTED
 * @front use DESELECT = ''
 */

/** Here we create/export enums that are used in 2 separate files */
export enum CaseOptions {
    POLICY = 'POLICY',
    REGULATORY = 'REGULATORY',
    PROCEDURE = 'PROCEDURE',
}

export enum CaseStage {
    ASSIGN_PARSE = 'ASSIGN_PARSE',
    OBLIGATION_VERIFICATION = 'OBLIGATION_VERIFICATION',
    AU_MAPPING = 'AU_MAPPING',
    CONTROL_MAPPING = 'CONTROL_MAPPING',
}

export enum DocumentTypeEnum {
    CFR = 'CFR',
    ASIC = 'ASIC',
    GENERIC = 'GENERIC',
}

export enum StatusChose {
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    DESELECT = '',
}

export enum UserRole {
    ANALYST = 'CM-Analyst',
    REVIEWER = 'CM-Reviewer',
}

export enum routeNames {
    Dashboard = 'Dashboard',
    Home = 'Home',
    Profile = 'Profile',
    Logout = 'Logout',
    DCM = 'Document Change Management',
    REGLIB = 'Regulation Library',
}

export enum CaseStatus {
    ACTION_REQUIRED = 'ACTION_REQUIRED',
    CANCELLED = 'CANCELLED',
    CLOSED = 'CLOSED',
    IN_PROGRESS = 'IN_PROGRESS',
    IN_REVIEW = 'IN_REVIEW',
    NEW = 'NEW',
    PROCESSING = 'PROCESSING',
}

export enum stepperRoutes {
    Home = 'home',
    Step2 = 'step2',
    Step3 = 'step3',
    Step4 = 'step4',
    Step5 = 'step5',
}

export enum RejectModalOperation {
    REJECTION = 'rejection',
    OVERVIEW = 'overview',
}

export enum ButtonType {
    SUBMIT = 'Submit',
    SAVE = 'Save',
}

export enum VariantChose {
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
}

export enum StatusBy {
    ANALYST = 'obligationApprovalStatusAnalyst',
    REVIEWER = 'obligationApprovalStatus',
}

export enum APIstate {
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
    FULFILLED = 'FULFILLED',
    CANCELLED = 'CANCELLED',
}

export enum StatusScreen {
    isSubDocumentExtraction = 'isSubmittedDocumentExtraction',
    isSubObligationsToAuTable = 'isSubmittedObligationsToAuTable',
    isSubControlMappingTable = 'isSubmittedControlMappingTable',
}

export enum TabOptions {
    option1 = 'selectFile',
    option2 = 'uploadFile',
    citationView = 'citationView',
    docView = 'docView',
}

export enum ObligationStatus {
    TOTAL_OBLIGATIONS = 'Total Obligations',
    PENDING_FOR_REVIEW = 'Pending for Review',
    // NEW = 'NEW',
    COMPLIANT = 'Compliant',
    NON_COMPLIANT = 'Non Compliant',
    NO_APPLICABLE = 'Non Applicable',
}
