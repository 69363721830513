/* eslint-disable sort-keys */
import { useParams } from 'react-router';
import DuplicateControls from './controlInventory/duplicateControls';
import ManualControls from './controlInventory/manualControls';
import RiskLinkageControls from './controlInventory/riskLinkageControls';
import DescriptionQualityControls from './controlInventory/descriptionQualityControls';
import { useEffect, useRef } from 'react';

function Inventory() {
    const { type } = useParams();
    const inventoryScreenRef: any = useRef(null);

    useEffect(() => {
        inventoryScreenRef && inventoryScreenRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [type]);
    const inventoryComponent = () => {
        switch (type) {
            case 'duplicate-controls':
                return <DuplicateControls />;
            case 'manual-controls':
                return <ManualControls />;
            case 'control-risk-linkage':
                return <RiskLinkageControls />;
            case 'control-description-quality':
                return <DescriptionQualityControls />;
            default:
                return <> </>;
        }
    };
    return <div ref={inventoryScreenRef}>{inventoryComponent()}</div>;
}

export default Inventory;
