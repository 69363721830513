import {
    REACT_APP_AUTH_TYPE,
    REACT_APP_AUTH_URL,
    REACT_APP_CLIENT_ID,
    REACT_APP_OAUTH_BASE_URL,
    REACT_APP_SCOPE,
} from '../config/settings';
import { setAuthToken, setAuthUser, setIdToken, setRefreshToken } from './authSlice';

import { AppDispatch } from '../store';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import queryString from 'query-string';

export const formatTokenData = (accessToken: any, expiresIn: number) => ({
    accessToken,
    tokenExpiration: Date.now() + expiresIn * 1000,
});
export const decodeIdToken = (token: any, dispatch: AppDispatch) => {
    if (!token) {
        return;
    }
    const { name, preferred_username, unique_name, email }: { [id: string]: string } = jwtDecode(token);
    dispatch(setAuthUser({ name, email: unique_name || preferred_username || email }));
};

/**
 * Extracts access and id token data from window.location, dispatching appropriate actions afterward to
 * setup the Redux store.
 * @param {*} dispatch
 */
export const getDataFromUri: (dispatch: AppDispatch, callBack: Function) => void = async (
    dispatch: AppDispatch,
    callBack: Function,
) => {
    switch (REACT_APP_AUTH_TYPE) {
        case 'OAUTH':
            await getTokenDataFromCode(dispatch);
            await getTokenDataFromUriLocation(dispatch);
            callBack && callBack();
            break;
        default:
            await getTokenDataFromUriLocation(dispatch);
            callBack && callBack();
            break;
    }
};
export const getTokenDataFromUriLocation = (dispatch: AppDispatch) => {
    const { hash, pathname, search } = window.location;
    const response = queryString.parse(hash);
    if (Object.keys(response).length) {
        const { access_token, id_token, expires_in } = response;
        if (access_token) {
            window.history.pushState('', document.title, `${pathname}${search}`);
            const tokenData = formatTokenData(access_token, Number(expires_in));
            dispatch(setAuthToken(tokenData));
            decodeIdToken(id_token, dispatch);
        }
    }
};

export const getTokenDataFromCode = async (dispatch: AppDispatch) => {
    const params: any = new URLSearchParams(window.location.search);
    if (params.size === 0) return;
    const paramsObject: any = {};
    for (const [key, value] of params) {
        // Prepend a marker character ('$') to the property name, this is to prevent any potential injection attacks
        const safeKey = `$${key}`;
        paramsObject[safeKey] = value;
    }

    if (paramsObject.$code) {
        const code_verifier = sessionStorage.getItem('code_verifier');
        const data: any = {
            client_id: REACT_APP_CLIENT_ID,
            code: paramsObject.$code,
            code_verifier,
            grant_type: 'authorization_code',
            redirect_uri: REACT_APP_AUTH_URL,
        };

        try {
            const response: any = await axios.post(`${REACT_APP_OAUTH_BASE_URL}/token`, new URLSearchParams(data), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'shouldBypassAuthorizationRefresh': true,
                },
            });
            if (response) {
                const tokenData = formatTokenData(response.data.access_token, Number(response.data.expires_in));
                dispatch(setAuthToken(tokenData));
                dispatch(setRefreshToken(response.data.refresh_token));
                dispatch(setIdToken(response.data.id_token));
                decodeIdToken(response.data.id_token, dispatch);
                sessionStorage.removeItem('code_verifier');
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }
};

function generateRandomString(length: number) {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const randomValues = new Uint32Array(length);
    const randomArray = window.crypto?.getRandomValues(randomValues);
    if (randomArray?.length > 0) {
        const maxValidValue = Math.floor(Number.MAX_SAFE_INTEGER / charset.length) * charset.length;
        for (let i = 0; i < length; i++) {
            let randomIndex;
            do {
                randomIndex = randomArray[i];
            } while (randomIndex >= maxValidValue);
            randomIndex %= charset.length;
            result += charset[randomIndex];
        }
    }
    return result;
}

function sha256(plain: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return crypto.subtle.digest('SHA-256', data);
}

function base64urlencode(array: ArrayBuffer) {
    let str = '';
    const bytes = new Uint8Array(array);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        str += String.fromCharCode(bytes[i]);
    }
    return btoa(str).split('+').join('-').split('/').join('_').split('=').join('');
}

export async function generateCodeVerifierAndChallenge() {
    const codeVerifier = generateRandomString(128);
    const codeChallengeBuffer = await sha256(codeVerifier);
    const codeChallenge = base64urlencode(codeChallengeBuffer);
    return { codeVerifier, codeChallenge };
}

export async function getNewAccessToken(refreshToken: string, dispatch: AppDispatch) {
    const data: any = {
        client_id: REACT_APP_CLIENT_ID,
        grant_type: 'refresh_token',
        redirect_uri: REACT_APP_AUTH_URL,
        refresh_token: refreshToken,
        scope: REACT_APP_SCOPE,
    };
    const options = {
        data: new URLSearchParams(data),
        headers: {
            shouldBypassAuthorizationRefresh: true,
        },
        method: 'POST',
        url: `${REACT_APP_OAUTH_BASE_URL}/token`,
    };
    try {
        const response = await axios.request(options);
        const tokenData = formatTokenData(response.data.access_token, Number(response.data.expires_in));
        dispatch(setAuthToken(tokenData));
        dispatch(setRefreshToken(response.data.refresh_token));
        dispatch(setIdToken(response.data.id_token));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
}

export async function revokeToken(token: string) {
    const data: any = {
        token: token,
        client_id: REACT_APP_CLIENT_ID,
    };
    const res = await axios.post(`${REACT_APP_OAUTH_BASE_URL}/revoke`, new URLSearchParams(data), {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'shouldBypassAuthorizationRefresh': true,
        },
    });
    return res;
}
