import React, { useState } from 'react';
import './customTabs.scss';

interface TabProps {
    title: string | React.ReactNode;
    component: React.ReactNode;
    card?: boolean;
    cardInfo?: React.ReactNode;
}

interface TabsProps {
    tabs: TabProps[];
}
const CustomTabsComponent: React.FC<TabsProps> = ({ tabs }: any) => {
    const [activeTab, setActiveTab] = useState(tabs?.indexOf(tabs?.find((tab: any) => !tab.card)));

    const handleTabClick = (index: number) => {
        setActiveTab(index);
    };

    return (
        <div className='custom-tabs'>
            <div className='custom-tab-titles'>
                {tabs.map((tab: any, index: number) => {
                    return tab.card ? (
                        <div className='tab-card'>{tab.title}</div>
                    ) : (
                        <div
                            key={index}
                            className={`custom-tab-title ${activeTab === index ? 'active' : ''}`}
                            onClick={() => handleTabClick(index)}
                        >
                            {tab.title}
                        </div>
                    );
                })}
            </div>
            <div className='custom-tab-content' key={activeTab}>
                {tabs[activeTab].component}
            </div>
        </div>
    );
};

export default CustomTabsComponent;
