/* tslint:disable */
/* eslint-disable */
/**
 * Nfr Ms Ds User Management API
 * Nfr Ms Ds User Management API documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'detail'?: string;
    /**
     * 
     * @type {Error}
     * @memberof ModelError
     */
    'cause'?: Error;
}
/**
 * Page object containing objects
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * Searched content
     * @type {Array<object>}
     * @memberof Page
     */
    'content'?: Array<object>;
    /**
     * is it first page 
     * @type {boolean}
     * @memberof Page
     */
    'first'?: boolean;
    /**
     * is it last page 
     * @type {boolean}
     * @memberof Page
     */
    'last'?: boolean;
    /**
     * How many pages in total
     * @type {number}
     * @memberof Page
     */
    'totalPages'?: number;
    /**
     * How many elements in total for searched criteria
     * @type {number}
     * @memberof Page
     */
    'totalElements'?: number;
    /**
     * current page number
     * @type {number}
     * @memberof Page
     */
    'number'?: number;
    /**
     * How many elements per page are there
     * @type {number}
     * @memberof Page
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface RequestCreateUserDTO
 */
export interface RequestCreateUserDTO {
    /**
     * 
     * @type {string}
     * @memberof RequestCreateUserDTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestCreateUserDTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestCreateUserDTO
     */
    'lastName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestCreateUserDTO
     */
    'roles'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ResponseCreateUserDTO
 */
export interface ResponseCreateUserDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof ResponseCreateUserDTO
     */
    'createdUserIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResponseCreateUserDTO
     */
    'errors'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RoleDTO
 */
export interface RoleDTO {
    /**
     * 
     * @type {string}
     * @memberof RoleDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDTO
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface RoleUserDTO
 */
export interface RoleUserDTO {
    /**
     * 
     * @type {string}
     * @memberof RoleUserDTO
     */
    'id'?: string;
    /**
     * 
     * @type {RoleDTO}
     * @memberof RoleUserDTO
     */
    'role'?: RoleDTO;
    /**
     * 
     * @type {UserDTO}
     * @memberof RoleUserDTO
     */
    'user'?: UserDTO;
}
/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'referenceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface UserDetailsDTO
 */
export interface UserDetailsDTO {
    /**
     * 
     * @type {UserDTO}
     * @memberof UserDetailsDTO
     */
    'user'?: UserDTO;
    /**
     * 
     * @type {Array<RoleDTO>}
     * @memberof UserDetailsDTO
     */
    'roles'?: Array<RoleDTO>;
}
/**
 * The User Search Pageable
 * @export
 * @interface UserPageDTO
 */
export interface UserPageDTO {
    /**
     * 
     * @type {Array<UserDTO>}
     * @memberof UserPageDTO
     */
    'content'?: Array<UserDTO>;
    /**
     * is it first page 
     * @type {boolean}
     * @memberof UserPageDTO
     */
    'first'?: boolean;
    /**
     * is it last page 
     * @type {boolean}
     * @memberof UserPageDTO
     */
    'last'?: boolean;
    /**
     * How many pages in total
     * @type {number}
     * @memberof UserPageDTO
     */
    'totalPages'?: number;
    /**
     * How many elements in total for searched criteria
     * @type {number}
     * @memberof UserPageDTO
     */
    'totalElements'?: number;
    /**
     * current page number
     * @type {number}
     * @memberof UserPageDTO
     */
    'number'?: number;
    /**
     * How many elements per page are there
     * @type {number}
     * @memberof UserPageDTO
     */
    'size'?: number;
}

/**
 * RolesResourceApi - axios parameter creator
 * @export
 */
export const RolesResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all available roles in the system
         * @summary Get all roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesResourceApi - functional programming interface
 * @export
 */
export const RolesResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all available roles in the system
         * @summary Get all roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesResourceApi - factory interface
 * @export
 */
export const RolesResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesResourceApiFp(configuration)
    return {
        /**
         * Get all available roles in the system
         * @summary Get all roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles(options?: any): AxiosPromise<Array<RoleDTO>> {
            return localVarFp.getRoles(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesResourceApi - object-oriented interface
 * @export
 * @class RolesResourceApi
 * @extends {BaseAPI}
 */
export class RolesResourceApi extends BaseAPI {
    /**
     * Get all available roles in the system
     * @summary Get all roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesResourceApi
     */
    public getRoles(options?: AxiosRequestConfig) {
        return RolesResourceApiFp(this.configuration).getRoles(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserRolesResourceApi - axios parameter creator
 * @export
 */
export const UserRolesResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Requires ADMIN user role  Roles can be set by email and name of role or by user id and role id
         * @summary Set role for the user
         * @param {RoleUserDTO} roleUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserRoles: async (roleUserDTO: RoleUserDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleUserDTO' is not null or undefined
            assertParamExists('createUserRoles', 'roleUserDTO', roleUserDTO)
            const localVarPath = `/role-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleUserDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Requires ADMIN user role  Removes role user by id (UUID)
         * @summary Delete role for the user
         * @param {string} id Role user id (UUID)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserRoles: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserRoles', 'id', id)
            const localVarPath = `/role-users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get roles for user provided in authorization token. Uses email and synchronize roles from token with these from database.  When user is not created also creates new user with default role.
         * @summary Get all roles for the user and set roles from token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/role-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRolesResourceApi - functional programming interface
 * @export
 */
export const UserRolesResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserRolesResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * Requires ADMIN user role  Roles can be set by email and name of role or by user id and role id
         * @summary Set role for the user
         * @param {RoleUserDTO} roleUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserRoles(roleUserDTO: RoleUserDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleUserDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserRoles(roleUserDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Requires ADMIN user role  Removes role user by id (UUID)
         * @summary Delete role for the user
         * @param {string} id Role user id (UUID)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserRoles(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserRoles(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get roles for user provided in authorization token. Uses email and synchronize roles from token with these from database.  When user is not created also creates new user with default role.
         * @summary Get all roles for the user and set roles from token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleUserDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserRolesResourceApi - factory interface
 * @export
 */
export const UserRolesResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserRolesResourceApiFp(configuration)
    return {
        /**
         * Requires ADMIN user role  Roles can be set by email and name of role or by user id and role id
         * @summary Set role for the user
         * @param {RoleUserDTO} roleUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserRoles(roleUserDTO: RoleUserDTO, options?: any): AxiosPromise<RoleUserDTO> {
            return localVarFp.createUserRoles(roleUserDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Requires ADMIN user role  Removes role user by id (UUID)
         * @summary Delete role for the user
         * @param {string} id Role user id (UUID)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserRoles(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserRoles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get roles for user provided in authorization token. Uses email and synchronize roles from token with these from database.  When user is not created also creates new user with default role.
         * @summary Get all roles for the user and set roles from token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRoles(options?: any): AxiosPromise<Array<RoleUserDTO>> {
            return localVarFp.getUserRoles(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserRolesResourceApi - object-oriented interface
 * @export
 * @class UserRolesResourceApi
 * @extends {BaseAPI}
 */
export class UserRolesResourceApi extends BaseAPI {
    /**
     * Requires ADMIN user role  Roles can be set by email and name of role or by user id and role id
     * @summary Set role for the user
     * @param {RoleUserDTO} roleUserDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesResourceApi
     */
    public createUserRoles(roleUserDTO: RoleUserDTO, options?: AxiosRequestConfig) {
        return UserRolesResourceApiFp(this.configuration).createUserRoles(roleUserDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Requires ADMIN user role  Removes role user by id (UUID)
     * @summary Delete role for the user
     * @param {string} id Role user id (UUID)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesResourceApi
     */
    public deleteUserRoles(id: string, options?: AxiosRequestConfig) {
        return UserRolesResourceApiFp(this.configuration).deleteUserRoles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get roles for user provided in authorization token. Uses email and synchronize roles from token with these from database.  When user is not created also creates new user with default role.
     * @summary Get all roles for the user and set roles from token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesResourceApi
     */
    public getUserRoles(options?: AxiosRequestConfig) {
        return UserRolesResourceApiFp(this.configuration).getUserRoles(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersResourceApi - axios parameter creator
 * @export
 */
export const UsersResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Requires ADMIN user role  Creates new users. You can create one or many users. Required fields for every user are email, firstname and lastname
         * @summary Create users
         * @param {Array<RequestCreateUserDTO>} requestCreateUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsers: async (requestCreateUserDTO: Array<RequestCreateUserDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestCreateUserDTO' is not null or undefined
            assertParamExists('createUsers', 'requestCreateUserDTO', requestCreateUserDTO)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestCreateUserDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Requires ADMIN user role  Delete user by given id
         * @summary Delete user with given id
         * @param {string} id Id of user to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserById', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user by given id (UUID)
         * @summary Get user with given id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserById', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get details of user taken from token and returns all user details with roles
         * @summary Get user details for user in token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDetails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all users data in the system   You can filter results by:    - role name - filter users by role name   - role id - filter users by role id   - user ids - filter users by user ids
         * @summary Get all users
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {string} [roleName] Filter users with given role name
         * @param {string} [roleId] Filter users with given role id
         * @param {Array<string>} [userIds] Filter users with given user ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (page?: number, size?: number, sort?: Array<string>, roleName?: string, roleId?: string, userIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (roleName !== undefined) {
                localVarQueryParameter['roleName'] = roleName;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersResourceApi - functional programming interface
 * @export
 */
export const UsersResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * Requires ADMIN user role  Creates new users. You can create one or many users. Required fields for every user are email, firstname and lastname
         * @summary Create users
         * @param {Array<RequestCreateUserDTO>} requestCreateUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUsers(requestCreateUserDTO: Array<RequestCreateUserDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreateUserDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUsers(requestCreateUserDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Requires ADMIN user role  Delete user by given id
         * @summary Delete user with given id
         * @param {string} id Id of user to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get user by given id (UUID)
         * @summary Get user with given id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get details of user taken from token and returns all user details with roles
         * @summary Get user details for user in token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDetails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDetails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all users data in the system   You can filter results by:    - role name - filter users by role name   - role id - filter users by role id   - user ids - filter users by user ids
         * @summary Get all users
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {string} [roleName] Filter users with given role name
         * @param {string} [roleId] Filter users with given role id
         * @param {Array<string>} [userIds] Filter users with given user ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(page?: number, size?: number, sort?: Array<string>, roleName?: string, roleId?: string, userIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(page, size, sort, roleName, roleId, userIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersResourceApi - factory interface
 * @export
 */
export const UsersResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersResourceApiFp(configuration)
    return {
        /**
         * Requires ADMIN user role  Creates new users. You can create one or many users. Required fields for every user are email, firstname and lastname
         * @summary Create users
         * @param {Array<RequestCreateUserDTO>} requestCreateUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsers(requestCreateUserDTO: Array<RequestCreateUserDTO>, options?: any): AxiosPromise<ResponseCreateUserDTO> {
            return localVarFp.createUsers(requestCreateUserDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Requires ADMIN user role  Delete user by given id
         * @summary Delete user with given id
         * @param {string} id Id of user to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user by given id (UUID)
         * @summary Get user with given id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(id: string, options?: any): AxiosPromise<UserDTO> {
            return localVarFp.getUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get details of user taken from token and returns all user details with roles
         * @summary Get user details for user in token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDetails(options?: any): AxiosPromise<UserDetailsDTO> {
            return localVarFp.getUserDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all users data in the system   You can filter results by:    - role name - filter users by role name   - role id - filter users by role id   - user ids - filter users by user ids
         * @summary Get all users
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {string} [roleName] Filter users with given role name
         * @param {string} [roleId] Filter users with given role id
         * @param {Array<string>} [userIds] Filter users with given user ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(page?: number, size?: number, sort?: Array<string>, roleName?: string, roleId?: string, userIds?: Array<string>, options?: any): AxiosPromise<UserPageDTO> {
            return localVarFp.getUsers(page, size, sort, roleName, roleId, userIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersResourceApi - object-oriented interface
 * @export
 * @class UsersResourceApi
 * @extends {BaseAPI}
 */
export class UsersResourceApi extends BaseAPI {
    /**
     * Requires ADMIN user role  Creates new users. You can create one or many users. Required fields for every user are email, firstname and lastname
     * @summary Create users
     * @param {Array<RequestCreateUserDTO>} requestCreateUserDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersResourceApi
     */
    public createUsers(requestCreateUserDTO: Array<RequestCreateUserDTO>, options?: AxiosRequestConfig) {
        return UsersResourceApiFp(this.configuration).createUsers(requestCreateUserDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Requires ADMIN user role  Delete user by given id
     * @summary Delete user with given id
     * @param {string} id Id of user to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersResourceApi
     */
    public deleteUserById(id: string, options?: AxiosRequestConfig) {
        return UsersResourceApiFp(this.configuration).deleteUserById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user by given id (UUID)
     * @summary Get user with given id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersResourceApi
     */
    public getUserById(id: string, options?: AxiosRequestConfig) {
        return UsersResourceApiFp(this.configuration).getUserById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get details of user taken from token and returns all user details with roles
     * @summary Get user details for user in token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersResourceApi
     */
    public getUserDetails(options?: AxiosRequestConfig) {
        return UsersResourceApiFp(this.configuration).getUserDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all users data in the system   You can filter results by:    - role name - filter users by role name   - role id - filter users by role id   - user ids - filter users by user ids
     * @summary Get all users
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned. Minimum value: 1
     * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
     * @param {string} [roleName] Filter users with given role name
     * @param {string} [roleId] Filter users with given role id
     * @param {Array<string>} [userIds] Filter users with given user ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersResourceApi
     */
    public getUsers(page?: number, size?: number, sort?: Array<string>, roleName?: string, roleId?: string, userIds?: Array<string>, options?: AxiosRequestConfig) {
        return UsersResourceApiFp(this.configuration).getUsers(page, size, sort, roleName, roleId, userIds, options).then((request) => request(this.axios, this.basePath));
    }
}



