import { NexusBadge } from '@nexus/react';
import React from 'react';
import './badge.scss';

interface InputProps {
    label: string;
    overlap?: '' | 'true' | 'inner' | 'middle' | 'outer' | undefined;
    variant?: 'default' | 'error' | 'success' | 'warning';
    class?: string;
    leftIcon?: React.ReactNode;
}
const BadgeComponent: React.FC<InputProps> = (props: InputProps) => {
    return (
        <>
            <NexusBadge overlap={props.overlap} variant={props.variant} className={props.class}>
                {props.leftIcon && <span>{props.leftIcon}</span>}
                {props.label}
            </NexusBadge>
        </>
    );
};

export default BadgeComponent;
