/* eslint-disable sort-keys */
import { CaseStage, UserRole } from './enums';

import i18n from 'locales/i18n';

export const ASC = 'ASC';
export const DESC = 'DESC';
export const limit = 10;
export const percentage = '%';
export const DefaultSelect = i18n.t<string>('uploadComponent.defaultSelect');
export const rejectionReasonCharactersLimit = 9;
export const dashboardPath = '/dashboard';
export const documentExtractionAndObligationPath = '/DocumentExtractionAndObligation';
export const obligationToAuMappingPath = '/ObligationToAuMapping';
export const obligationControlMappingPath = '/ControlMapping';
export const casesPath = '/case';
export const forceCancel = 'forceCancel';
export const FileType: any = {
    'application/pdf': '.pdf',
};
export const CASE_STEPS_ORDER = [CaseStage.OBLIGATION_VERIFICATION, CaseStage.AU_MAPPING, CaseStage.CONTROL_MAPPING];
export const CASE_STEPS = [
    {
        label: 'obligation',
        name: CaseStage.OBLIGATION_VERIFICATION,
    },
    {
        label: 'auMapping',
        name: CaseStage.AU_MAPPING,
    },
    {
        label: 'controlMapping',
        name: CaseStage.CONTROL_MAPPING,
    },
];
export const authRoles = [UserRole.ANALYST, UserRole.REVIEWER];

export const pageSizeOptions = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 25, value: 25 },
    { label: 50, value: 50 },
];

export const selectStatusValues = [
    {
        disabled: false,
        label: 'To Do',
        value: 'NEW',
    },
    {
        disabled: false,
        label: 'In Progress',
        value: 'IN_PROGRESS',
    },
    {
        disabled: false,
        label: 'Completed',
        value: 'COMPLETED',
    },
];

export const taskTypeOptions = [
    {
        label: 'Select Task Type',
        value: 'Select Task Type',
    },
    {
        label: 'Upload Evidence',
        value: 'Upload Evidence',
    },
    {
        label: 'Review Obligation',
        value: 'Review Obligation',
    },
    {
        label: 'Test Control Effectiveness',
        value: 'Test Control Effectiveness',
    },
];

export const defaultPageSize = 1000;

export const STATUS_COLOURS: {
    [key: string]: string;
} = {
    good: '#B3F3AF',
    moderate: '#FFDC73',
    significant: '#FFBE8F',
    critical: '#FF7E61',
};
