import { NexusIcon } from '@nexus/react';
import SingleLabelComponent from './singleLabelComponent';
import infoIcon from '@nexus/core/dist/assets/icons/alert/ic_info_24px.svg';
import trendUpIcon from '@nexus/core/dist/assets/icons/action/ic_trending_up_24px.svg';
import './component.scss';

const DetailsComponent = ({ className = '', id, unit, description }: any) => {
    return (
        <div className={'details-container ' + className}>
            <SingleLabelComponent label={id?.label} value={id?.value} />
            <SingleLabelComponent label={unit?.label} value={unit?.value} />
            <SingleLabelComponent label={description?.label} type='textarea' value={description?.value} />
        </div>
    );
};

export const CompareComponent = ({ className = '', selectedControl }: any) => {
    return (
        <div className={className}>
            <div className='details-compare-title-conatiner'>
                <div className='details-compare-title'>
                    <div>Similarity Range: {selectedControl?.similarity_score?.value}</div>
                    <NexusIcon src={infoIcon} size='sm' />
                </div>
                <div className='details-compare-title-stat'>
                    <NexusIcon src={trendUpIcon} size='sm' />
                    <div>{selectedControl?.risk_rating?.value}</div>
                </div>
            </div>
            <div className='details-compare'>
                <DetailsComponent
                    className={'left-container'}
                    id={selectedControl?.ref_prc_id}
                    unit={selectedControl?.ref_business_unit}
                    description={selectedControl?.ref_prc_description}
                />
                <DetailsComponent
                    className={'right-container'}
                    id={selectedControl?.tgt_prc_id}
                    unit={selectedControl?.tgt_business_unit}
                    description={selectedControl?.tgt_prc_description}
                />
            </div>
        </div>
    );
};
