/* eslint-disable sort-keys */
import FilterIcon from '@nexus/core/dist/assets/icons/action/ic_filter_list_24px.svg';
import { NexusButton, NexusIcon } from '@nexus/react';
import CustomTabsComponent from 'app/components/reusable/customTabs/customTabs';
import { retrieveUserName } from 'auth';
import { STATUS_COLOURS } from 'constants/constants';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useAppDispatch } from 'store';
import './dashboard.scss';
import MenuComponent from './menuComponent';
import { getBusinessEntitiesByKpi, getControlMetrics } from './redux/dashboard/dashboardReducer';
import StatusChip from './status';
import TabComponent from './tabComponent';
import { businessEntitiesSelector, controlMetricsSelector } from './redux/dashboard/dashboardSelector';

const CardInfo = ({ title, count, description, status }: any) => (
    <div className='card-info'>
        <div className='card-info-title'>{title} </div>
        <div className='card-info-content'>
            <div className='card-info-count'>{count}</div>
            <StatusChip status={status} />
        </div>
        <div className='card-info-description'>{description}</div>
    </div>
);

function Dashboard() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const fullName = useSelector(retrieveUserName);

    const businessData = useSelector(businessEntitiesSelector);
    console.log('businessData: ', businessData);
    const metricsData = useSelector(controlMetricsSelector);
    console.log('metricsData: ', metricsData);

    const [show, setShow] = React.useState(false);

    const processData = [
        {
            maxLabel: 'Critical',
            minLabel: 'Good',
            maxValue: 1200,
            description: 'Out of 1200 processes can be improved',
            value: 250,
            height: 200,
            width: 400,
            arcWidth: 40,
            title: 'Process Description Quality',
            status: 'Good',
            onClick: () => {},
        },
        {
            maxLabel: 'Critical',
            minLabel: 'Good',
            maxValue: 1200,
            description: 'Out of 1200 processes are incorrectly linked',
            value: 342,
            height: 200,
            width: 400,
            arcWidth: 40,
            title: 'Process incorrectly linked',
            status: 'Moderate',
            onClick: () => {},
        },
        {
            maxLabel: 'Critical',
            minLabel: 'Good',
            maxValue: 1200,
            description: 'Out of 1200 processes are duplicate',
            value: 578,
            height: 200,
            width: 400,
            arcWidth: 40,
            title: 'Duplicate process',
            status: 'Significant',
            onClick: () => {},
        },
        {
            maxLabel: 'Critical',
            minLabel: 'Good',
            maxValue: 1200,
            description: 'Out of 1200 processes are orphan',
            value: 996,
            height: 200,
            width: 400,
            arcWidth: 40,
            title: 'Orphan process',
            status: 'Critical',
            onClick: () => {},
        },
    ];
    const controlData = [
        {
            maxLabel: 'Critical',
            minLabel: 'Good',
            maxValue: 1200,
            description: 'Out of 1200 processes can be improved',
            value: 96,
            height: 200,
            width: 400,
            arcWidth: 40,
            title: 'Duplicate Controls . ',
            status: 'Good',
            progressColor: STATUS_COLOURS.good,
            onClick: () => {
                navigate('/inventory/duplicate-controls');
            },
        },
        {
            maxLabel: 'Critical',
            minLabel: 'Good',
            maxValue: 1200,
            description: 'Out of 1200 processes are incorrectly linked',
            value: 105,
            height: 200,
            width: 400,
            arcWidth: 40,
            title: 'Control to Risk Linkage .',
            status: 'Good',
            progressColor: STATUS_COLOURS.good,
            onClick: () => {
                navigate('/inventory/control-risk-linkage');
            },
        },
        {
            maxLabel: 'Critical',
            minLabel: 'Good',
            maxValue: 1200,
            description: 'Out of 1200 processes are duplicate',
            value: 495,
            height: 200,
            width: 400,
            arcWidth: 40,
            title: 'Manual Controls .',
            status: 'Moderate',
            progressColor: STATUS_COLOURS.moderate,
            onClick: () => {
                navigate('/inventory/manual-controls');
            },
        },
        {
            maxLabel: 'Critical',
            minLabel: 'Good',
            maxValue: 1200,
            description: 'Out of 1200 processes are orphan',
            value: 657,
            height: 200,
            width: 400,
            arcWidth: 40,
            title: 'Control Description Quality .',
            status: 'Significant',
            progressColor: STATUS_COLOURS.significant,
            onClick: () => {
                navigate('/inventory/control-description-quality');
            },
        },
    ];
    const tabs: any = [
        {
            id: 1,
            title: (
                <CardInfo
                    title={'Overall Inventory Score'}
                    count={51}
                    description={'2136 out of 4800 total items need attention.'}
                    status={'Critical'}
                />
            ),
            card: true,
        },
        {
            id: 1,
            title: (
                <CardInfo
                    title={'Process Inventory Score'}
                    count={45}
                    description={'2136 out of 4800 total items need attention.'}
                    status={'Significant'}
                />
            ),
            component: <TabComponent cardsData={processData} title={'Process Inventory'} />,
        },
        {
            id: 2,
            title: (
                <CardInfo
                    title={'Risk Inventory Score'}
                    count={8}
                    description={'4703 out of 4800 total items need attention.'}
                    status={'Critical'}
                />
            ),
            component: 'Tab 2 component',
        },
        {
            id: 3,
            title: (
                <CardInfo
                    title={'Control Inventory Score'}
                    count={98}
                    description={'384 out of 4800 total items need attention.'}
                    status={'Good'}
                />
            ),
            component: <TabComponent cardsData={controlData} title={'Process Inventory'} />,
        },
    ];

    const handleMenuShow = (open: boolean) => {
        setShow(open);
    };

    useEffect(() => {
        dispatch(getBusinessEntitiesByKpi('RISK'));
        dispatch(getControlMetrics('DUPLICATED_CONTROLS'));
    }, []);

    return (
        <div className='dashboard'>
            <div className='details'>
                <div className='last-updated'>Last update 10:23 AM</div>
                <div className='welcm-msg'>Welcome back, {fullName}!</div>
                <div className='desc'>Review the inventorys below to improve your scores.</div>
                <div className='filter-button'>
                    <NexusButton
                        className='btn'
                        type='button'
                        size='md'
                        variant='default'
                        onClick={() => handleMenuShow(true)}
                    >
                        <NexusIcon src={FilterIcon}></NexusIcon>
                        <span>Filter(0)</span>
                    </NexusButton>
                </div>
                <MenuComponent
                    position='right'
                    key={'filterComponent' + show}
                    show={show}
                    setShow={handleMenuShow}
                    attrId='menu'
                />
            </div>
            <CustomTabsComponent tabs={tabs} />
        </div>
    );
}

export default Dashboard;
