/* eslint-disable max-len */
/* eslint-disable sort-keys */
import { NexusIcon } from '@nexus/react';
import BreadcrumbComponent from 'app/components/nds/breadcrumb/breadcrumb';
import ButtonComponent from 'app/components/nds/button/button';
import filterIcon from '@nexus/core/dist/assets/icons/action/ic_filter_list_24px.svg';
import InventoryTitleCompoent from '../../components/inventoryTitleCompoent';
import InventoryTableComponent from '../../components/inventoryTableComponent';
import './manualControls.scss';
import SingleLabelComponent from '../../components/singleLabelComponent';
import { manualControls } from 'app/mockData';
import { useEffect, useState } from 'react';

function ManualControls() {
    const [selectedControl, setSelectedControl] = useState<any>(null);
    const columnsDef: any = [
        {
            cellClass: 'nexus-caption-copy',
            field: 'controlId',
            isSortable: false,
            label: 'Control ID ',
            minWidth: 50,
        },

        {
            cellClass: 'nexus-caption-copy',
            field: 'name',
            isSortable: false,
            label: 'Control name ',
            minWidth: 50,
        },
        {
            cellClass: 'nexus-caption-copy',
            field: 'score',
            isSortable: false,
            label: 'Risk rating',
            minWidth: 50,
        },
    ];

    useEffect(() => {
        handleRowClick(manualControls[0]);
    }, []);

    const handleRowClick = (row: any) => {
        setSelectedControl({
            controlId: {
                label: 'Control ID',
                value: row.controlId,
            },
            name: {
                label: 'Control Name',
                value: row.name,
            },
            automated: {
                label: 'Automated',
                value: 'No',
            },
            controlEffectiveness: {
                label: 'Control Effectiveness',
                value: row.effectiveness,
            },
            description: {
                label: 'Control Description',
                value: row.description,
            },
        });
    };
    return (
        <div className={'manual-control'}>
            <BreadcrumbComponent
                separator={'>'}
                data={[
                    {
                        name: 'PRC Dashboard',
                        isEnabled: true,
                        path: '/dashboard',
                        id: 0,
                    },
                    {
                        name: 'Manual controls',
                        isEnabled: false,
                        path: '',
                        id: 0,
                    },
                ]}
            />
            <div className='filter-container'>
                <ButtonComponent
                    extraClass='inventory-filter-btn'
                    type={['nexus-btn']}
                    label={'Filter (3)'}
                    leftIcon={<NexusIcon src={filterIcon} />}
                />
            </div>
            <InventoryTitleCompoent
                title={'Manual Controls'}
                description={
                    'In this page you will see the list of controls pairs that are either exact duplicates with other controls in the inventory, or have very similar wording.'
                }
                countDetails={[
                    {
                        title: 'Number of action items',
                        count: 96,
                    },
                    {
                        title: 'Duplicate Control Score',
                        count: 96,
                        status: 'Good',
                    },
                ]}
            />
            <div className='details-table-container'>
                <InventoryTableComponent
                    columnsDef={columnsDef}
                    data={manualControls}
                    pagination={true}
                    pageSizeCustom={5}
                    onRowClick={handleRowClick}
                />
                <div className='details-compare-container'>
                    {selectedControl && (
                        <>
                            <div className='control-details-container'>
                                <SingleLabelComponent
                                    label={selectedControl.controlId.label}
                                    value={selectedControl.controlId.value}
                                />
                                <SingleLabelComponent
                                    label={selectedControl.name.label}
                                    value={selectedControl.name.value}
                                />
                                <SingleLabelComponent
                                    label={selectedControl.automated.label}
                                    value={selectedControl.automated.value}
                                />
                                <SingleLabelComponent
                                    label={selectedControl.controlEffectiveness.label}
                                    value={selectedControl.controlEffectiveness.value}
                                />
                            </div>
                            <div className='control-details-description'>
                                <SingleLabelComponent
                                    label={selectedControl.description.label}
                                    value={selectedControl.description.value}
                                    type={'textarea'}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ManualControls;
