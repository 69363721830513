import { NexusIcon } from '@nexus/react';
import ButtonComponent from 'app/components/nds/button/button';
import CustomTable from 'app/components/nds/customTable/customTable';
import Select from 'app/components/nds/select/select';
import SearchboxPanel from 'app/components/reusable/searchboxPanel/SearchboxPanel';
import downloadIcon from '@nexus/core/dist/assets/icons/action/ic_download_24px.svg';
import './component.scss';

function InventoryTableComponent({ columnsDef, data = [], pagination = true, pageSizeCustom = 5, onRowClick }: any) {
    return (
        <div className='details-table'>
            <SearchboxPanel classes={'inventory-search'} label='View your action and access items here.' />
            <div className='table-filter'>
                <Select label='Select a risk rating to filter' classes='risk-filter' />
                <ButtonComponent
                    extraClass='inventory-download-btn'
                    type={['nexus-btn']}
                    label={'Download table'}
                    leftIcon={<NexusIcon src={downloadIcon} />}
                />
            </div>
            <CustomTable
                columnsDef={columnsDef}
                data={data}
                pagination={pagination}
                pageSizeCustom={pageSizeCustom}
                onRowClick={onRowClick}
            />
        </div>
    );
}

export default InventoryTableComponent;
