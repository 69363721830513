import { ITimeNotification } from 'constants/commonExportedInterfaces';
import TimeNotification from '../timeNotification/TimeNotification';
import './notificationsContainer.scss';
import { generateUniqueKey } from 'services/generateUniqueKey';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

interface INotificationsContainer {
    notifications: ITimeNotification[];
}

const Notification = ({ key, notification }: any) => {
    const dispatch = useDispatch();

    // auto close feature to remove notification after time from the store
    useEffect(() => {
        if (notification.time) {
            notification.action &&
                setTimeout(() => {
                    dispatch(notification.action(notification.id));
                }, notification.time);
        }
    }, []);
    return (
        <TimeNotification
            key={key}
            message={notification.message}
            variant={notification.variant}
            time={notification.time}
        />
    );
};
//*  You have to make parent of this component position: relative according for it to work properly
const NotificationsContainer: React.FC<INotificationsContainer> = ({ notifications }) => {
    return (
        <>
            {!!notifications?.length && (
                <div className='notifications-container' data-testid='notifications-container'>
                    {notifications.map((notification: ITimeNotification, index: number) => (
                        <Notification key={generateUniqueKey(index)} notification={notification} />
                    ))}
                </div>
            )}
        </>
    );
};

export default NotificationsContainer;
