import React from 'react';
import './filter.scss';
import { businessData, lineOfBusinessData, regionData, sectorData } from './mockData';
import CustomSelectWithPills from './select/customSelectWithPills';
import { NexusButton } from '@nexus/react';

interface FilterComponentProps {}

const FilterComponent: React.FC<FilterComponentProps> = () => {
    const clearRef = React.useRef<any>(null);
    const handleClearMethod = () => {
        clearRef.current?.handleClear();
    };
    return (
        <div className='filter-container'>
            <div className='filter-title'>Filter your dashboard</div>
            <div className='filter-description'>
                <p>
                    Please select the options to filter the dashboard information. The selection will display the
                    possible combinations of filtering.
                </p>
            </div>
            <div className='filter-section'>
                <div className='filter-items'>
                    <p>Region</p>
                    <div className='region-select'>
                        <CustomSelectWithPills
                            data={regionData}
                            type={'region'}
                            multiSelect={true}
                            ref={clearRef}
                            use={'regions'}
                        />
                    </div>
                </div>
                <div className='filter-item'>
                    <p>Line of Business</p>
                    <div className='lob-select'>
                        <CustomSelectWithPills
                            data={lineOfBusinessData}
                            type={'lob'}
                            multiSelect={false}
                            ref={clearRef}
                            use={'lob'}
                        />
                    </div>
                </div>
                <div className='filter-item'>
                    <p>Sector</p>
                    <div className='sector-select'>
                        <CustomSelectWithPills
                            data={sectorData}
                            type={'sector'}
                            multiSelect={false}
                            ref={clearRef}
                            use='sector'
                        />
                    </div>
                </div>
                <div className='filter-item'>
                    <p>Business</p>
                    <div className='business-select'>
                        <CustomSelectWithPills
                            data={businessData}
                            type={'business'}
                            multiSelect={false}
                            ref={clearRef}
                            use='business'
                        />
                    </div>
                </div>
            </div>
            <div className='button-section'>
                <NexusButton
                    className='btn'
                    type='button'
                    size='md'
                    variant='default'
                    onClick={() => handleClearMethod()}
                >
                    <span>Clear all</span>
                </NexusButton>
                <NexusButton
                    className='btn filter'
                    type='button'
                    size='md'
                    variant='default'
                    // onClick={() => handleFilter()}
                >
                    <span>Apply Filters</span>
                </NexusButton>
            </div>
        </div>
    );
};

export default FilterComponent;
