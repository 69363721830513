/* eslint-disable sort-keys */
import { NexusButton, NexusCard } from '@nexus/react';
import GaugeChartComponent from 'app/components/nds/guageChart';
import React from 'react';
import './controlMetricsCard.scss';
import StatusChip from '../status';
function MetricCard({
    maxLabel = '',
    minLabel = '',
    value = 0,
    description = '',
    maxValue = 0,
    progressColor = '#00FF00',
    height = 400,
    width = 500,
    arcWidth = 50,
    title = '',
    status = '',
    onClick,
}: any) {
    return (
        <NexusCard className='control-tab-card'>
            <div className='control-tab-card-title-container'>
                <div className='control-tab-card-title'>{title}</div>
                <StatusChip status={status} />
            </div>
            <div className='control-tab-content'>
                <GaugeChartComponent
                    arcWidth={arcWidth}
                    height={height}
                    width={width}
                    value={value}
                    description={description}
                    transitionDuration={5000}
                    maxValue={maxValue}
                    config={{
                        chart: {
                            transitionDuration: 5000,
                        },
                        axis: {
                            maxLabel,
                            minLabel,
                        },
                        color: {
                            indicatorColor: 'black',
                            pathBackgroundColor: '#e3eeef',
                            progressColor,
                        },
                        font: {
                            descriptionColor: 'black',
                            descriptionFontFamily: 'interstate',
                            descriptionFontSize: '14px',
                            labelColor: 'black',
                            labelFontSize: '10px',
                            valueColor: 'black',
                            valueFontFamily: 'interstate',
                            valueFontSize: '32px',
                        },
                    }}
                />
                <NexusButton onClick={onClick}>View Details</NexusButton>
            </div>
        </NexusCard>
    );
}

export default MetricCard;
