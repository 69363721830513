import { format } from 'date-fns';

export const deleteAllCookies = () => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
};

export const formatDate = (date: string) => {
    const formattedDate = format(new Date(date), 'MM/dd/yyyy HH:mm');
    return formattedDate;
};

export const overDueFinder = (dueDate: string) => {
    const currentDate = new Date();
    const taskDueDate = new Date(dueDate);
    if (currentDate > taskDueDate) {
        return true;
    }
    return false;
};

export const statusFinder = (status: string) => {
    switch (status) {
        case 'NEW':
            return 'To Do';
        case 'COMPLETED':
            return 'Completed';
        case 'IN_PROGRESS':
            return 'In Progress';
        default:
            return '';
    }
};

export const colorFinder = (status: string) => {
    switch (status) {
        case 'NEW':
            return 'badge-todo';
        case 'COMPLETED':
            return 'badge-complete';
        case 'IN_PROGRESS':
            return 'badge-inprogress';
        default:
            return '';
    }
};

export const chartStatusFinder = (status: string) => {
    switch (status) {
        case 'PENDING_FOR_REVIEW':
            return 'Pending for review';
        case 'COMPLIANT':
            return 'Compliant';
        case 'NOT_COMPLIANT':
        case 'NON_COMPLIANT':
            return 'Non Compliant';
        case 'PARTIALLY_COMPLIANT':
            return 'Partially Compliant';
        case 'NO_RELATION_TO_US':
            return 'No relation to business line';
        case 'MISSING_FILE':
            return 'Missing file';
        case 'CONTINGENT_LIABILITY':
            return 'Contingent liability';
        case 'NEW':
            return 'New';
        default:
            return '';
    }
};

export const percentageCalculator = (types: any, value: number) => {
    const totalCount = types.reduce((total: any, item: any) => total + (item.count || 0), 0);
    return ((value / totalCount) * 100).toFixed(0);
};
