/**
 * Active Directory Authentication component. Uses Redux store to manage token data and hidden iframe for
 * refreshes.
 */

/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { authenticate, authenticateSilently, logout } from './adAuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAuthToken,
    selectAuthEmail,
    setAuthToken,
    selectIsAuthenticated,
    setInitialState,
    selectIsAuthTokenExpired,
    selectIsLogoutRequested,
} from '../authSlice';
import { getValueFromSearchString } from '../../utils/tokenUtils';
import { AppDispatch, RootState } from '../../store/index';
import { formatTokenData, getTokenDataFromUriLocation } from '../authHelper';

/**
 * Responds to the iframe load checking for an access token in session storage
 * @param {*} dispatch
 */
export const handleIframeLoad = (dispatch: AppDispatch) => {
    // This is stored in the tokenRefresh.html file
    const tokenString = window.sessionStorage.getItem('tokenRenewalString');
    if (!tokenString) {
        return;
    }

    window.sessionStorage.removeItem('tokenRenewalString');
    const accessToken = getValueFromSearchString('access_token', tokenString);
    const expiresIn = Number(getValueFromSearchString('expires_in', tokenString));
    const tokenData = formatTokenData(accessToken, expiresIn);

    dispatch(setAuthToken(tokenData));
};

interface InputProps {
    children: React.ReactNode;
    store?: RootState;
    doLogout?: boolean;
}

const ADAuth = ({ children, doLogout, store }: InputProps) => {
    const [showChildren, setShowChildren] = useState(false);
    const dispatch = useDispatch();
    const [iframeSrc, setIframeSrc] = useState('');
    // Check store (redux) for the token, if not available the auth request will occur.
    // Could persist the token so refreshes don't force a refetch but that introduces potential
    // security risks
    const accessToken = useSelector(selectAuthToken);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const isTokenExpired = useSelector(selectIsAuthTokenExpired);
    const isLogoutRequested = useSelector(selectIsLogoutRequested);
    const loggingOut = isLogoutRequested || doLogout;
    const email = useSelector(selectAuthEmail);

    useEffect(() => {
        if (isTokenExpired) {
            authenticateSilently(email, setIframeSrc);
        }
    }, [isTokenExpired]);

    useEffect(() => {
        if (!loggingOut) {
            getTokenDataFromUriLocation(dispatch);
        }
    }, [store]);

    useEffect(() => {
        if (!loggingOut) {
            accessToken ? setShowChildren(true) : authenticate();
        }
    }, [accessToken]);

    useEffect(() => {
        if (loggingOut) {
            dispatch(setInitialState(''));
            setShowChildren(false);
            logout();
        }
    }, [loggingOut]);

    return (
        <>
            {showChildren && isAuthenticated && (
                <>
                    {children}
                    <iframe
                        title={'tokenRefresh'}
                        src={iframeSrc}
                        style={{ border: 0, display: 'block', height: 0, width: 0 }}
                        sandbox='allow-popups'
                    />
                </>
            )}
            {showChildren && !isAuthenticated && <>Not Authenticated - Check env settings.</>}
        </>
    );
};

export default ADAuth;
