import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';

export const businessEntitiesSelector = createSelector(
    (state: RootState) => state.dashboardData,
    (dashboardData) => dashboardData?.businessEntities,
);

export const controlMetricsSelector = createSelector(
    (state: RootState) => state.dashboardData,
    (dashboardData) => dashboardData?.controlMetrics,
);
