/* eslint-disable sort-keys */
import { NexusIcon } from '@nexus/react';
import BreadcrumbComponent from 'app/components/nds/breadcrumb/breadcrumb';
import ButtonComponent from 'app/components/nds/button/button';
import filterIcon from '@nexus/core/dist/assets/icons/action/ic_filter_list_24px.svg';
import InventoryTitleCompoent from '../../components/inventoryTitleCompoent';
import InventoryTableComponent from '../../components/inventoryTableComponent';
import { CompareComponent } from '../../components/compareComponent';
import './duplicateControls.scss';
import { duplicateControls } from 'app/mockData';
import { useEffect, useState } from 'react';

function DuplicateControls() {
    const [selectedControl, setSelectedControl] = useState<any>(null);

    const columnsDef: any = [
        {
            cellClass: 'nexus-caption-copy',
            field: 'controlID',
            isSortable: true,
            label: 'Control ID',
            minWidth: 50,
        },
        {
            cellClass: 'nexus-caption-copy',
            field: 'ref_prc_id',
            isSortable: false,
            label: 'PRC ID',
            minWidth: 50,
        },
        {
            cellClass: 'nexus-caption-copy',
            field: 'ref_business_unit',
            isSortable: true,
            label: 'Business Unit',
            minWidth: 50,
        },
        {
            cellClass: 'nexus-caption-copy',
            field: 'tgt_prc_id',
            isSortable: false,
            label: 'PRC ID',
            minWidth: 50,
        },
        {
            cellClass: 'nexus-caption-copy',
            field: 'tgt_business_unit',
            isSortable: true,
            label: 'Business Unit',
            minWidth: 50,
        },
    ];

    useEffect(() => {
        handleRowClick(duplicateControls[0]);
    }, []);

    const handleRowClick = (row: any) => {
        setSelectedControl({
            ref_prc_id: {
                label: 'Ref PRC ID',
                value: row.ref_prc_id,
            },
            controlID: {
                label: 'Control ID',
                value: row.controlID,
            },
            ref_prc_description: {
                label: 'Ref PRC Description',
                value: row.ref_prc_description,
            },
            ref_business_unit: {
                label: 'Ref Business Unit',
                value: row.ref_business_unit,
            },
            tgt_prc_id: {
                label: 'Tgt PRC ID',
                value: row.tgt_prc_id,
            },
            tgt_prc_description: {
                label: 'Tgt PRC Description',
                value: row.tgt_prc_description,
            },
            tgt_business_unit: {
                label: 'Tgt Business Unit',
                value: row.tgt_business_unit,
            },
            similarity_score: {
                label: 'Similarity Score',
                value: row.similarity_score,
            },
            similarity_category: {
                label: 'Similarity Category',
                value: row.similarity_category,
            },
            similarity_BU: {
                label: 'Similarity BU',
                value: row.similarity_BU,
            },
            risk_rating: {
                label: 'Risk Rating',
                value: row.risk_rating,
            },
        });
    };
    return (
        <div className={'duplicate-control'}>
            <BreadcrumbComponent
                separator={'>'}
                data={[
                    {
                        name: 'PRC Dashboard',
                        isEnabled: true,
                        path: '/dashboard',
                        id: 0,
                    },
                    {
                        name: 'Duplicate controls',
                        isEnabled: false,
                        path: '',
                        id: 0,
                    },
                ]}
            />
            <div className='filter-container'>
                <ButtonComponent
                    extraClass='inventory-filter-btn'
                    type={['nexus-btn']}
                    label={'Filter (3)'}
                    leftIcon={<NexusIcon src={filterIcon} />}
                />
            </div>
            <InventoryTitleCompoent
                title={'Duplicate Controls'}
                description={
                    'In this page you will see the list of controls pairs that are either exact duplicates with other controls in the inventory, or have very similar wording.'
                }
                countDetails={[
                    {
                        title: 'Number of action items',
                        count: 96,
                    },
                    {
                        title: 'Duplicate Control Score',
                        count: 96,
                        status: 'Good',
                    },
                ]}
            />
            <div className='details-table-container'>
                <InventoryTableComponent
                    columnsDef={columnsDef}
                    data={duplicateControls}
                    pagination={true}
                    pageSizeCustom={5}
                    onRowClick={handleRowClick}
                />
                <div className='details-compare-container'>
                    <CompareComponent selectedControl={selectedControl} />
                </div>
            </div>
        </div>
    );
}

export default DuplicateControls;
