/* eslint-disable max-len */
/* eslint-disable sort-keys */
import { NexusIcon } from '@nexus/react';
import BreadcrumbComponent from 'app/components/nds/breadcrumb/breadcrumb';
import ButtonComponent from 'app/components/nds/button/button';
import filterIcon from '@nexus/core/dist/assets/icons/action/ic_filter_list_24px.svg';
import InventoryTitleCompoent from '../../components/inventoryTitleCompoent';
import InventoryTableComponent from '../../components/inventoryTableComponent';
import './riskLinkageControls.scss';
import SingleLabelComponent from '../../components/singleLabelComponent';

function RiskLinkageControls() {
    const columnsDef: any = [
        {
            cellClass: 'nexus-caption-copy',
            field: 'pageNumber',
            isSortable: false,
            label: 'Control ID A',
            minWidth: 50,
        },
        {
            cellClass: 'nexus-caption-copy',
            field: 'newText',
            isSortable: true,
            label: 'Control ID B',
            minWidth: 75,
        },
        {
            cellClass: 'nexus-caption-copy',
            field: 'newText',
            isSortable: true,
            label: 'Similarity Range',
            minWidth: 75,
        },
        {
            cellClass: 'nexus-caption-copy',
            field: 'newText',
            isSortable: true,
            label: 'Risk Rating',
            minWidth: 75,
        },
    ];

    const details = [
        {
            label: 'Control ID',
            value: 'ctr_10044',
        },
        {
            label: 'Control Name',
            value: 'Dual Controls',
        },
        {
            label: 'Automated',
            value: 'No',
        },
        {
            label: 'Control Effectiveness',
            value: 'Effective',
        },
    ];
    return (
        <div className={'risk-linkage-control'}>
            <BreadcrumbComponent
                separator={'>'}
                data={[
                    {
                        name: 'PRC Dashboard',
                        isEnabled: true,
                        path: '/dashboard',
                        id: 0,
                    },
                    {
                        name: 'Control to Risk Linkage',
                        isEnabled: false,
                        path: '',
                        id: 0,
                    },
                ]}
            />
            <div className='filter-container'>
                <ButtonComponent
                    extraClass='inventory-filter-btn'
                    type={['nexus-btn']}
                    label={'Filter (3)'}
                    leftIcon={<NexusIcon src={filterIcon} />}
                />
            </div>
            <InventoryTitleCompoent
                title={'Control to Risk Linkage'}
                description={
                    'In this page you will see the list of controls pairs that are either exact duplicates with other controls in the inventory, or have very similar wording.'
                }
                countDetails={[
                    {
                        title: 'Number of action items',
                        count: 96,
                    },
                    {
                        title: 'Duplicate Control Score',
                        count: 96,
                        status: 'Good',
                    },
                ]}
            />
            <div className='details-table-container'>
                <InventoryTableComponent columnsDef={columnsDef} data={[]} pagination={true} pageSizeCustom={5} />
                <div className='details-compare-container'>
                    <div className='control-details-container'>
                        {details.map((detail) => {
                            return <SingleLabelComponent label={detail.label} value={detail.value} />;
                        })}
                    </div>
                    <div className='control-details-description'>
                        <SingleLabelComponent
                            label={'Control Description'}
                            value={
                                'A maker/checker control is utilized by Mask_54 Mask_110 to ensure that prior to making a subject mortgage loan (i) the property securing the loan is covered by flood insurance prior to funding the loan, and (Mask_113) that the insurance amount is at least equal to the lesser of the outstanding principal balance of the designated loan or the maximum limit of coverage available for the particular type of property under the Act . Prior to funding the loan, the loan coordinator (Mask_197) obtains and reviews documentation evidencing appropriate proof of coverage, confirming accuracy of information (e.g., correct insured, servicer, mortgagee, address, flood zone, effective date, etc .) and correct coverage amount in accordance with Mask_104 requirements . After the Mask_197 completes their review, the documentation is forwarded to an Operations Manager (or designee) to perform a second review . Upon satisfactory review, the manager places an â€œ Mask_123 â€ note code with the description â€œ 0 â€ in the Mask_114 to indicate that the flood insurance is appropriate . The initial review is documented by the Mask_197 as Maker by checking off the â€œ Flood Insurance Reviewed & Uploaded â€ box on the Loan Coordinator Checklist . The Operations Manager â€™ s (or designee â€™ s) Mask_123 note code confirms the second level review . To ensure that Mask_54 Mask_110 only accepts private flood insurance policies that meet the requirements of 12 Mask_103 Mask_28 (k), prior to funding, the Shared Services team reviews all private policies and must complete the Private Flood Insurance Checklist located within the Loan Coordinator Checklist and save to Mask_58 . The Shared Services team member will then enter the Mask_123 note code with the description â€œ 1 â€ to indicate that the review was completed.'
                            }
                            type={'textarea'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RiskLinkageControls;
