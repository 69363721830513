import { NexusIcon, NexusInput, NexusSearch } from '@nexus/react';
import ActionIcSearch24px from '@nexus/core/dist/assets/icons/action/ic_search_24px.svg';
import React from 'react';

interface ISearchboxPanel {
    classes?: string;
    label?: string;
}

const SearchboxPanel: React.FC<ISearchboxPanel> = ({ classes, label }) => {
    return (
        <div className={`${classes ?? 'nexus-col nexus-m-1'}`}>
            {label && <div className='search-label'>{label}</div>}
            <NexusSearch>
                <NexusInput autocomplete='off' placeholder='Search'>
                    <NexusIcon src={ActionIcSearch24px}></NexusIcon>
                </NexusInput>
            </NexusSearch>
        </div>
    );
};

export default SearchboxPanel;
