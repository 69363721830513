import './avatar.scss';

import { NexusAvatar } from '@nexus/react';
import React from 'react';

const AvatarComponent: React.FC<{ name: string; size: 'sm' | 'xs' | 'md' | 'lg' | 'xl' | undefined }> = ({
    name,
    size,
}) => {
    return <NexusAvatar data-testid='avatar' size={size} label={name} />;
};

export default AvatarComponent;
