import { Configuration as CRConfig, BusinessEntityResourceApiFactory } from 'app/api/crAPI';
import { Configuration as RegConfig, ObligationResourceApiFactory, SectionResourceApiFactory } from 'app/api/regLibAPI';

export const apiConstantREGLIB = `${process.env.REACT_APP_BASE_URL}/api/reglib`;
export const apiConstantTASK = `${process.env.REACT_APP_BASE_URL}/api/tasks`;
export const apiConstantCR = `${process.env.REACT_APP_API_BASE_URL}/api/cr-data`;

export const regLibApiConfig: RegConfig = {
    basePath: apiConstantREGLIB,
    isJsonMime: function (): boolean {
        return true;
    },
};

export const taskApiConfig: RegConfig = {
    basePath: apiConstantTASK,
    isJsonMime: function (): boolean {
        return true;
    },
};

export const crApiConfig: CRConfig = {
    basePath: apiConstantCR,
    isJsonMime: function (): boolean {
        return true;
    },
};

const sectionResourceApiFactory = SectionResourceApiFactory(regLibApiConfig);
const obligationResourceApiFactory = ObligationResourceApiFactory(regLibApiConfig);
const businessEntityResourceApiFactory = BusinessEntityResourceApiFactory(crApiConfig);

export { sectionResourceApiFactory, obligationResourceApiFactory, businessEntityResourceApiFactory };
