import { PayloadAction } from '@reduxjs/toolkit';
import {
    BusinessEntityResourceApiFactory,
    BusinessEntityType,
    ControlMetric,
    ControlResourceApiFactory,
} from 'app/api/crAPI';
import { crApiConfig } from 'constants/apiConstant';
import { VariantChose } from 'constants/enums';
import { addNotification, removeNotification } from 'services/notifications';
import { createAppSlice } from 'store/sliceHelper';

const INITIAL_STATE_DASHBOARD: any = {
    data: [],
    loading: false,
    notifications: [],
};

const businessEntityResourceApiFactory = BusinessEntityResourceApiFactory(crApiConfig);
const controlResourceApiFactory = ControlResourceApiFactory(crApiConfig);

type INITIAL_STATE_DASHBOARD_TYPE = Readonly<typeof INITIAL_STATE_DASHBOARD>;

const dashboardData = createAppSlice({
    initialState: INITIAL_STATE_DASHBOARD as INITIAL_STATE_DASHBOARD_TYPE,
    name: 'dashboardData',
    reducers: (create) => {
        return {
            getBusinessEntitiesByKpi: create.asyncThunk(
                async (businessEntityType: BusinessEntityType, { rejectWithValue }) => {
                    try {
                        const response = await businessEntityResourceApiFactory.getBusinessEntitiesByKpi(
                            businessEntityType,
                        );
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state: typeof INITIAL_STATE_DASHBOARD, action: PayloadAction<any>) => {
                        state.loading = false;
                        state.businessEntities = action.payload?.data;
                        state.notifications = removeNotification(state, 'getBusinessEntitiesByKpi');
                    },
                    pending: (state: typeof INITIAL_STATE_DASHBOARD) => {
                        state.loading = true;
                        state.notifications = removeNotification(state, 'getBusinessEntitiesByKpi');
                    },
                    rejected: (state: typeof INITIAL_STATE_DASHBOARD, action: PayloadAction<any>) => {
                        state.loading = false;
                        state.notifications = addNotification(state, {
                            action: removeNotification,
                            id: 'getBusinessEntitiesByKpi',
                            message: action.payload.message,
                            time: 5000,
                            variant: VariantChose.ERROR,
                        });
                    },
                },
            ),
            getControlMetrics: create.asyncThunk(
                async (metricName: ControlMetric, { rejectWithValue }) => {
                    try {
                        const response = await controlResourceApiFactory.getControlMetrics(metricName);
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state: typeof INITIAL_STATE_DASHBOARD, action: PayloadAction<any>) => {
                        state.loading = false;
                        state.controlMetrics = action.payload?.data;
                        state.notifications = removeNotification(state, 'getControlMetrics');
                    },
                    pending: (state: typeof INITIAL_STATE_DASHBOARD) => {
                        state.loading = true;
                        state.notifications = removeNotification(state, 'getControlMetrics');
                    },
                    rejected: (state: typeof INITIAL_STATE_DASHBOARD, action: PayloadAction<any>) => {
                        state.loading = false;
                        state.notifications = addNotification(state, {
                            action: removeNotification,
                            id: 'getControlMetrics',
                            message: action.payload.message,
                            time: 5000,
                            variant: VariantChose.ERROR,
                        });
                    },
                },
            ),
        };
    },
});

export const { getBusinessEntitiesByKpi, getControlMetrics } = dashboardData.actions;
export default dashboardData.reducer;
