import React from 'react';
import { NexusBreadcrumb, NexusBreadcrumbItem } from '@nexus/react';
import './breadcrumb.scss';

interface Props {
    separator: string;
    data: {
        isEnabled: boolean;
        name: string;
        path: string;
        id: number;
    }[];
}

const BreadcrumbComponent: React.FC<Props> = ({ separator, data }) => {
    return (
        <>
            {/* <p className='nexus-h5'>Custom Content</p> */}
            <NexusBreadcrumb separator={separator}>
                {data.map((breadcrumbItem: { name: string; path: string; isEnabled: boolean; id: number }) => {
                    return (
                        <NexusBreadcrumbItem
                            key={breadcrumbItem.id}
                            data-testid={breadcrumbItem.name}
                            disabled={breadcrumbItem.isEnabled}
                            className={
                                'nexus-breadcrumb-item' +
                                (breadcrumbItem.isEnabled
                                    ? ' nexus-breadcrumb-item-enabled'
                                    : ' nexus-breadcrumb-item-disabled')
                            }
                        >
                            <a href={breadcrumbItem.path}>{breadcrumbItem.name}</a>
                        </NexusBreadcrumbItem>
                    );
                })}
            </NexusBreadcrumb>
        </>
    );
};

export default BreadcrumbComponent;
