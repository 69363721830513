import React from 'react';
import './statusChip.scss';
import { STATUS_COLOURS } from 'constants/constants';
function StatusChip({ status }: { status: string }) {
    return (
        <div className='status' style={{ backgroundColor: STATUS_COLOURS[status.trim().toLowerCase()] }}>
            {status}
        </div>
    );
}

export default StatusChip;
