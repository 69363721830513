import { StatusBy, UserRole, VariantChose } from './enums';

/** Here we create/export interfaces that are used in 2 separate files */

export interface IStep {
    key?: string;
    label?: string;
    name: string;
    onClick?: () => void;
}

export interface ITimeNotification {
    message: string;
    time: number;
    variant: VariantChose;
}

export interface SelectOption {
    disabled?: boolean;
    label: string;
    value: any;
}

export type IFile = {
    name: string;
    size: number;
};

export interface IHeader {
    field: string;
    isSortable?: boolean;
    label: string;
    id?: number;
    minWidth?: number;
    minWidthHeader?: number;
}

export interface AvailableAnalyst {
    userId: string;
    userFullName: string;
    userEmail: string;
    userRole?: string;
}

export interface ISelectDocument {
    documentRefId: string;
    documentName: string;
    documentCategory: string;
    documentCommonName: string;
    documentUrl: string;
    documentVersion: number;
    documentLastModified: string;
    documentLastModifiedBy: string;
}

export interface SelectedDoc {
    docId: string;
    docName: string;
    dateModified: string;
    docUrl: string;
}

/** Here we create/export types that are used in 2 separate files */

export type DecisionDescriptor = UserRole | StatusBy;
export type DataDescriptor = ISelectDocument[];

export interface PaginationQueryParameters {
    page?: number;
    size?: number;
    sort?: string;
}

interface SortFromTablesTemplates {
    ascending: boolean;
    descending: boolean;
    direction: string;
    ignoreCase: boolean;
    nullHandling: string;
    property: string;
}

export interface TablesTemplate<TableContent> {
    totalPages: number;
    totalElements: number;
    sort: SortFromTablesTemplates[];
    numberOfElements: number;
    pageable: {
        sort: SortFromTablesTemplates[];
        pageNumber: number;
        pageSize: number;
        paged: boolean;
        unpaged: boolean;
        offset: number;
    };
    first: boolean;
    last: boolean;
    size: number;
    content: TableContent[];
    number: number;
    empty: boolean;
}

export type APICallState = 'FULFILLED' | 'PENDING' | 'REJECTED' | '' | 'CANCELLED';

export type CustomErrorConstructorData = {
    detail: string;
    title: string;
    message: string;
    code?: string;
};

export class CustomError extends Error {
    readonly title: string;
    readonly detail: string;
    readonly code: string;
    // base constructor only accepts string message as an argument
    // we extend it here to accept an object, allowing us to pass other data
    constructor({ detail, message, title, code }: CustomErrorConstructorData) {
        super(message);
        this.title = title;
        this.detail = detail;
        this.code = code || '';
    }
}

export type Modify<T, R> = Omit<T, keyof R> & R;
