import './container.scss';

import { AuthProvider, refreshAuthToken, selectAccessToken, selectTokenExpiration } from '../../auth';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from 'app/components/nds/button/button';
import HeaderComponent from '../components/nds/header/header';
import LoaderComponent from 'app/components/nds/loader/loader';
import ModalComponent from 'app/components/reusable/modal/modal';
import NotificationsContainer from 'app/components/reusable/notificationsContainer/NotificationsContainer';
// import Unauthorized from 'app/views/unauthorized/unauthorized';
// import { authRoles } from 'constants/constants';
import { routeNames } from '../../constants/enums';
// import { selectRole } from 'auth';
import store from '../../store';
import i18n from 'locales/i18n';

type Props = {
    children?: React.ReactNode;
};
const headerNavBarData = [
    {
        id: 1,
        link: '/dashboard',
        title: routeNames.Dashboard,
    },
];

const AuthContainer = ({ children }: Props) => {
    const IDLE_MINUTES = 15 * 60;
    const FIVE_MINUTES = 5 * 60;
    const TOKEN_EXPIRATION_WINDOW_MILLIS = 10 * 60 * 1000;

    const [time, setTime] = useState(IDLE_MINUTES); // Time in minutes
    const [show, setShow] = useState(false);

    // const roles = useSelector(selectRole);
    const tokenExpirationTime = useSelector(selectTokenExpiration);
    const accessToken = useSelector(selectAccessToken);

    const dispatch = useDispatch();
    //----------------- refresh the token if it is about to expire -----------------//
    useEffect(() => {
        // using settimeout to refresh the token 10 minutes before it expires
        const timeout = setTimeout(() => {
            if (accessToken) {
                dispatch(refreshAuthToken());
            }
        }, tokenExpirationTime - Date.now() - TOKEN_EXPIRATION_WINDOW_MILLIS);
        return () => clearTimeout(timeout);
    }, [tokenExpirationTime]);
    //------------------------------------------------------------------------------------------//

    //----------------- Track user activity based on mouse and keyboard events -----------------//
    const triggerTimerModal = (status: boolean) => {
        setShow(status);
        sessionStorage.setItem('show', status + '');
    };
    useEffect(() => {
        if (time === 0) {
            if (show) {
                triggerTimerModal(false);
                window.location.href = window.location.origin + '/logout';
            } else {
                triggerTimerModal(true);
                setTime(FIVE_MINUTES);
            }
            // Your logic to handle session timeout
        }
    }, [time]);
    useEffect(() => {
        const handleActivity = () => {
            // Your logic to handle user activity
            const isModalOpen = sessionStorage.getItem('show');
            if (isModalOpen !== 'true') {
                setTime(IDLE_MINUTES);
            }
        };

        const timer = () => {
            // Your logic to reset the timer
            setTime((prevTime) => {
                return prevTime - 1;
            });
        };

        // Attach event listeners
        document.addEventListener('mousemove', handleActivity);
        document.addEventListener('keydown', handleActivity);

        // Reset timer on user activity
        const activityTimer = setInterval(timer, 1000);

        // Clean up event listeners and timer on component unmount
        return () => {
            document.removeEventListener('mousemove', handleActivity);
            document.removeEventListener('keydown', handleActivity);
            clearInterval(activityTimer);
        };
    }, []);
    //------------------------------------------------------------------------------------------//

    //-----------------  Redirect to login if user is not authenticated and then redirect back to initial url-----------------//
    const currPath = window.location.pathname;
    const redirectUrl = localStorage.getItem('redirectUrl') || '';

    if (!accessToken) {
        if (!redirectUrl) localStorage.setItem('redirectUrl', currPath);
        window.location.assign(window.location.origin + '/');
    }
    if (redirectUrl && redirectUrl === currPath) {
        localStorage.removeItem('redirectUrl');
    }
    if (accessToken && redirectUrl && redirectUrl !== currPath) {
        window.location.assign(window.location.origin + redirectUrl);
        localStorage.removeItem('redirectUrl');
    }
    //--------------------------------------------------------------------------------------------------------------------------//

    const formatTime = (time: number): string => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const isLoading = false;
    const notifications: any = [];
    // Check if authRoles does not contain any of the names from the roles array
    // const isAuth = roles && !roles?.some((role: any) => authRoles.includes(role?.name));
    // if (!roles || isAuth) {
    //     return <Unauthorized />;
    // }
    return (
        <React.Fragment>
            <AuthProvider store={store}>
                <HeaderComponent menuItems={headerNavBarData} home='/dashboard' />
                <div className='hero-image'>
                    <NotificationsContainer notifications={notifications} />
                    <LoaderComponent show={isLoading} fullscreen={true} />
                    <ModalComponent
                        show={show}
                        closeModal={() => {
                            triggerTimerModal(false);
                            setTime(IDLE_MINUTES);
                        }}
                        header={<div className='nexus-pt-2 inactive-header'> {i18n.t<string>('modal.inactive')}</div>}
                        size='xl'
                        footer={
                            <>
                                <ButtonComponent
                                    label={'Continue'}
                                    extraClass='nexus-ml-auto'
                                    type={['nexus-btn nexus-btn-medium']}
                                    click={() => {
                                        triggerTimerModal(false);
                                        setTime(IDLE_MINUTES);
                                    }}
                                />
                            </>
                        }
                    >
                        <div>
                            <div className='timer-container'>
                                <p>{i18n.t<string>('modal.inactiveMsg')}</p> <p className='timer'>{formatTime(time)}</p>
                            </div>
                            <div> {i18n.t<string>('modal.inactiveMessage')}</div>
                        </div>
                    </ModalComponent>
                    <div className='idle-time'>
                        {i18n.t<string>('modal.idleTime')}
                        {formatTime(time)}
                    </div>
                    <div>{children}</div>
                </div>
            </AuthProvider>
        </React.Fragment>
    );
};

export default AuthContainer;
