import BadgeComponent from 'app/components/nds/badge/badge';
import CustomSelect from 'app/components/reusable/customSelect';
import { forwardRef, useImperativeHandle, useState } from 'react';
import './customSelectWithPills.scss';

interface dataProps {
    id: number;
    value: string;
    label: string;
}

interface CustomSelectWithPills {
    data: dataProps[];
    type: string;
    multiSelect: boolean;
    use?: string;
}

const Region = ({ name, type }: { name: string; type: string }) => {
    return (
        <div className='region-select'>
            <p>
                {name} {type === 'region' && <input type='checkbox' />}
            </p>
        </div>
    );
};

const Sector = ({ name }: { name: string }) => {
    return (
        <div className='normal-select'>
            <span>{name}</span>
        </div>
    );
};

const CustomSelectWithPills = forwardRef<any, CustomSelectWithPills>(({ data, type, multiSelect, use }, ref) => {
    const [value, setValue] = useState<any>({
        regions: [],
        lob: '',
        sector: '',
        business: '',
    });

    const variant = 'default';

    const handleRegionSelect = (e: any) => {
        const regions = e.map((item: any) => item.value);
        setValue((prevValue: any) => ({
            ...prevValue,
            regions: [...prevValue.regions, ...regions],
        }));
    };

    const handleSelect = (e: any, type: string) => {
        setValue((prevValue: any) => ({
            ...prevValue,
            [type]: [...prevValue[type], e],
        }));
    };

    const handleSelectChange = (e: any, diff: string) => {
        if (type === 'region') {
            handleRegionSelect(e);
        } else {
            handleSelect(e, diff);
        }
    };

    const customiseOptions: any = data.map((item) => {
        return {
            id: item.id,
            optionLabel: <Region name={item.label} type={type} />,
            selectedLabel: <BadgeComponent label={item.label} variant={variant} />,
            value: item.value,
            label: use,
        };
    });

    const normalOptions: any = data.map((item) => {
        return {
            id: item.id,
            optionLabel: <Region name={item.label} type={type} />,
            selectedLabel: <Sector name={item.label} />,
            value: item.value,
            label: use,
        };
    });

    const handleClear = () => {
        setValue({
            regions: [],
            lob: '',
            sector: '',
            business: '',
        });
    };

    useImperativeHandle(ref, () => {
        return {
            handleClear,
        };
    });

    const handleCloseClick = (item: any) => {
        const selections = value?.regions?.filter((selectedItem: any) => selectedItem !== item);
        setValue((prevValue: any) => ({
            ...prevValue,
            regions: selections,
        }));
    };

    return (
        <div className='custom-select'>
            <div className='custom-select-container'>
                <div className='placeholder'>
                    <CustomSelect
                        type={type === 'region' ? 'SELECT_1' : 'SELECT_3'}
                        data={type === 'region' ? customiseOptions : normalOptions}
                        onChange={(e: any, diff: string) => handleSelectChange(e, diff)}
                        className='regions'
                        value={value}
                        multiSelect={multiSelect ? true : false}
                    />
                </div>
            </div>
            {value?.regions?.length > 0 && (
                <div className='pill-container'>
                    {value?.regions?.map((item: any) => (
                        <div onClick={() => handleCloseClick(item)}>
                            <BadgeComponent label={item.toUpperCase()} variant={variant} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
});

export default CustomSelectWithPills;
