import './header.scss';

import { Link, useLocation } from 'react-router-dom';
import { NexusHeader, NexusHeaderLogo, NexusHeaderMenu, NexusIcon, NexusMenuItem } from '@nexus/react';
import React, { useEffect, useState } from 'react';

import AvatarComponent from '../avatar/avatar';
import DropdownComponent from '../dropdown/dropdown';
import Logo from '../../../assets/images/logo.svg';
import { getAvatarLabel } from 'services/avatarLabel';
import { retrieveUserName } from '../../../../auth';
import { routeNames } from 'constants/enums';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

interface menuItem {
    icon?: string;
    link: string;
    title: string;
    id?: number;
}
interface Props {
    home: string;
    menuItems: menuItem[];
}
const HeaderComponent: React.FC<Props> = ({ home, menuItems }) => {
    const path = useLocation();
    const navigate = useNavigate();
    const fullName = useSelector(retrieveUserName);

    const [toggleDropDown, setToggleDropDown] = useState<boolean>(false);
    const closeMenu = () => {
        const closeBtn = document.getElementsByTagName('nexus-menu')[0]?.getElementsByTagName('nexus-icon');
        return closeBtn && closeBtn.length > 0 ? closeBtn[0].click() : null;
    };
    const dropDownMenu = () => {
        setToggleDropDown(!toggleDropDown);
    };
    const handleClickOutside = (event: MouseEvent) => {
        const dropdown = document.querySelector('.user-profile-flyout');
        if (dropdown && !dropdown.contains(event.target as Node)) {
            setToggleDropDown(false);
        }
    };
    const handleNavigate = (link: any) => {
        navigate(link);
    };
    const dropdownOptions = [
        {
            clickButton: () => handleNavigate('/profile'),
            extraStyle: 'link-color',
            label: routeNames.Profile,
            link: '/profile',
        },
        {
            clickButton: () => handleNavigate('/logout'),
            extraStyle: 'link-color',
            label: routeNames.Logout,
            link: '/logout',
        },
    ];
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
    }, []);
    return (
        <NexusHeader class='nexus-header-sticky nexus-header hydrated header-width' data-testid='nexus-header'>
            <NexusHeaderLogo className='display-inline padding nexus-py-2'>
                <div onClick={() => handleNavigate(home)} className='header-pointer'>
                    <NexusIcon src={Logo} />
                </div>
            </NexusHeaderLogo>
            <NexusHeaderMenu data-testid='nexus-header-menu'>
                {menuItems.map((menuItem, idx) =>
                    menuItem.icon ? (
                        <NexusMenuItem key={idx} data-testid={menuItem.title} onClick={closeMenu}>
                            <Link to={menuItem.link}>
                                <NexusIcon src={menuItem.icon}>{menuItem.title}</NexusIcon>
                            </Link>
                        </NexusMenuItem>
                    ) : (
                        <NexusMenuItem
                            className={`${menuItem.link}` === path.pathname ? 'menu-item-highlighter' : ''}
                            key={idx}
                            data-testid={menuItem.title}
                            onClick={closeMenu}
                        >
                            <div onClick={() => handleNavigate(menuItem.link)} className='header-pointer'>
                                {menuItem.title}
                            </div>
                        </NexusMenuItem>
                    ),
                )}
                <NexusMenuItem>
                    <div className='nexus-mt-1 user-profile-flyout' onClick={() => dropDownMenu()}>
                        <DropdownComponent
                            toggleDropDown={toggleDropDown}
                            dropdownOptions={dropdownOptions}
                            triggererContent={<AvatarComponent name={getAvatarLabel(fullName)} size='lg' />}
                            placement='left'
                            dropdownType='basic'
                            height={280}
                            width={245}
                        />
                    </div>
                </NexusMenuItem>
            </NexusHeaderMenu>
        </NexusHeader>
    );
};

export default HeaderComponent;
