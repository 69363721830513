import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { UserDetailsDTO, UsersResourceApiFactory } from 'app/api/userManagementAPI';

import { RootState } from '../store';
import { getAccessToken } from './authService';
import jwtDecode from 'jwt-decode';
import { regLibApiConfig } from 'constants/apiConstant';

const usersResourceApiFactory = UsersResourceApiFactory(regLibApiConfig);
interface props {
    accessToken: string | null;
    refreshToken: string | null;
    idToken: string | null;
    tokenExpiration: number | null;
    isAuthenticated: boolean;
    tokenRefreshPending: boolean;
    logoutPending: boolean;
    email: string | null;
    fullName: string | null;
    loading?: boolean;
    disableAuthRefresh: boolean;
}
export const INITIAL_STATE: props = {
    accessToken: null,
    disableAuthRefresh: false,
    email: null,
    fullName: null,
    idToken: null,
    isAuthenticated: false,
    loading: false,
    logoutPending: false,
    refreshToken: null,
    tokenExpiration: null,
    tokenRefreshPending: false,
};

export interface JWTToken {
    exp?: number;
    given_name: string;
    family_name: string;
    name: string;
    unique_name: string;
}

// Use async thunk to wait for access token
export const fetchToken = createAsyncThunk('auth/fetchToken', () => getAccessToken());
//Updated API call to get user details along with the user role
export const getUserDetails = createAsyncThunk('auth/getUserRole', async (_, { rejectWithValue, getState }) => {
    try {
        const {
            auth: { accessToken },
        }: any = getState();
        const response: { data: UserDetailsDTO } = await usersResourceApiFactory.getUserDetails(accessToken);
        return response;
    } catch (error: any) {
        return rejectWithValue(error.detail);
    }
});

// Selectors for quick access to specific data

// Expose access to the access token through selector
export const selectAuthToken = createSelector(
    (state: RootState) => state.auth,
    (auth) => auth.accessToken,
);

export const selectRefreshToken = createSelector(
    (state: RootState) => state.auth,
    (auth) => auth.refreshToken,
);
export const selectIDToken = createSelector(
    (state: RootState) => state.auth,
    (auth) => auth.idToken,
);

// Expose access to the access token's expiration in millis
export const selectAuthTokenExpiration = createSelector(
    (state: RootState) => state.auth,
    (auth) => auth.tokenExpiration,
);

// Expose access to the access token's expiration indicator
export const selectIsAuthTokenExpired = createSelector(
    (state: RootState) => state.auth,
    (auth) => auth.tokenRefreshPending,
);

// Expose access to the flag indicating user is authenticated
export const selectIsLogoutRequested = createSelector(
    (state: RootState) => state.auth,
    (auth) => auth.logoutPending,
);

// Expose access to the authenticated user by email through selector
export const selectAuthEmail = createSelector(
    (state: RootState) => state.auth,
    (auth) => (auth ? auth.email : ''),
);

// Expose access to the flag indicating user is authenticated
export const selectIsAuthenticated = createSelector(
    (state: RootState) => state.auth,
    (auth) => auth.isAuthenticated,
);

export const retrieveUserName = createSelector(
    (state: RootState) => state.auth,
    (auth) => auth.fullName,
);

export const selectRole = createSelector(
    (state: RootState) => state.auth,
    (auth) => auth.userRoles,
);

export const selectAccessToken = createSelector(
    (state: RootState) => state.auth,
    (auth) => auth.accessToken,
);

export const selectTokenExpiration = createSelector(
    (state: RootState) => state.auth,
    (auth) => auth.tokenExpiration,
);

export const selectUserId = createSelector(
    (state: RootState) => state.auth,
    (auth) => auth.userId,
);

export const authSlice: {
    [id: string]: any;
} = createSlice({
    extraReducers: (builder) =>
        builder
            .addCase(fetchToken.fulfilled, (state, action) => ({
                ...state,
                accessToken: action.payload,
                isAuthenticated: Boolean(action.payload),
                logoutPending: false,
                tokenRefreshPending: false,
            }))
            .addCase(getUserDetails.fulfilled, (state, action) => {
                return {
                    ...state,
                    userId: action.payload?.data?.user?.id,
                    userRoles: action.payload?.data?.roles,
                };
            }),
    initialState: INITIAL_STATE,
    name: 'auth',
    reducers: {
        logout: (state) => {
            state.logoutPending = true;
            state.tokenRefreshPending = true;
        },
        refreshAuthToken: (state) => {
            state.tokenRefreshPending = true;
        },
        // An action types is generated per reducer
        setAuthToken: {
            reducer: (state, action: PayloadAction<props>) => {
                try {
                    // validate token with a decode attempt
                    const decodedToken: JWTToken = jwtDecode(
                        action.payload.accessToken ? action.payload.accessToken : '',
                    );
                    jwtDecode(action.payload.accessToken ? action.payload.accessToken : '');
                    state.accessToken = action.payload.accessToken;
                    state.tokenExpiration = action.payload.tokenExpiration;
                    state.isAuthenticated = true;
                    state.tokenRefreshPending = false;
                    state.logoutPending = false;
                    state.email = decodedToken.unique_name;
                    state.fullName = decodedToken.given_name;
                } catch (error) {
                    state.accessToken = ''; //'INVALID_TOKEN';
                    state.tokenExpiration = null;
                    state.isAuthenticated = false;
                    state.tokenRefreshPending = false;
                    state.logoutPending = false;
                    state.loading = false;
                }
            },
            prepare: (token) => {
                return { payload: token };
            },
        },
        setAuthUser: {
            reducer: (state, action: PayloadAction<{ email: string; name: string }>) => {
                state.email = action.payload.email;
                state.fullName = action.payload.name;
            },
            prepare: (user) => ({ payload: user }),
        },
        setDisableAuthRefresh: (state, action) => {
            state.disableAuthRefresh = action.payload;
        },
        setIdToken: (state, action) => {
            state.idToken = action.payload;
        },
        setInitialState: () => INITIAL_STATE,
        setLoading: {
            reducer: (state, action: PayloadAction<boolean>) => {
                state.loading = action.payload;
            },
            prepare: (loading) => ({ payload: loading }),
        },
        setRefreshToken: (state, action) => {
            state.refreshToken = action.payload;
        },
    },
});

export const {
    logout,
    refreshAuthToken,
    setAuthToken,
    setAuthUser,
    setInitialState,
    setLoading,
    setRefreshToken,
    setIdToken,
    setDisableAuthRefresh,
} = authSlice.actions;
export default authSlice.reducer;
