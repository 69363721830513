import React from 'react';
import StatusChip from '../../dashboard/status';
import { NexusIcon } from '@nexus/react';
import infoIcon from '@nexus/core/dist/assets/icons/alert/ic_info_24px.svg';
import './component.scss';

function InventoryTitleCompoent({ title, description, countDetails }: any) {
    return (
        <div className='inventory-first-section'>
            <div className='inventory-title-container'>
                <div className='inventory-title'>
                    {title}
                    <NexusIcon src={infoIcon} size='sm' />
                </div>
                <div className='inventory-description'>{description} </div>
            </div>
            <div className='nexus-flex-row count-details'>
                {countDetails.map((item: any, index: number) => (
                    <div key={index}>
                        <div className='count-details-title'>{item.title}</div>
                        <div className='nexus-flex-row count-details'>
                            <div className='count-details-count'>96</div>
                            {item?.status && <StatusChip status={item.status} />}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default InventoryTitleCompoent;
