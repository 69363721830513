import './index.scss';

import App from './app/App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

/** Beginning with React 18, when in development mode, the components will be mounted, unmounted, and then mounted once again in StrictMode.
 *  React offers a “Strict Mode” in which it calls each component’s function twice during development.
 *  By calling the component functions twice, Strict Mode helps find components that break these rules.
 *  Strict Mode has no effect in production, so it won’t slow down the app for your users.
 *  To opt into Strict Mode, you can wrap your root component into <React.StrictMode>.
 */
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    // <React.StrictMode>
    <App />,
    // </React.StrictMode>,
);
reportWebVitals();
