import React, { useState } from 'react';

import { NexusSelect } from '@nexus/react';
import { NexusSelectCustomEvent } from '@nexus/core';

interface InputProps {
    attrId?: string;
    delimiter?: string;
    disabled?: boolean;
    multiple?: boolean;
    required?: boolean;
    value?: string;
    classes?: string;
    type?: 'custom' | 'native';
    selectedValue?: string;
    showSelectedValueOnInit?: boolean;

    options?:
        | {
              label: string;
              value: string;
              disabled?: boolean;
              id?: string;
          }[]
        | any;

    customOnChange?: (nextValue?: any, nextLabel?: any) => void;
    selectedType?: string;
    disabledOption?: string;
    name?: string;
    key?: string | number;
    placeholder?: string;
    label?: string;
}

const Select: React.FC<InputProps> = (props: InputProps) => {
    const [value, setValue] = useState(props.selectedValue ? props.selectedValue : '');
    const onChangeOpts = (option: any) => {
        setValue(option?.label);
        if (props.customOnChange) {
            props.customOnChange(option?.value, option?.label);
        }
    };
    return (
        <div className={props.classes || ''}>
            {props?.label && <div className='select-label'>{props?.label}</div>}
            <NexusSelect
                type='custom'
                placeholder={props.placeholder}
                key={props.key}
                value={props.showSelectedValueOnInit ? props.selectedValue : value}
                attrId={props.attrId}
                disabled={props.disabled}
                multiple={props.multiple}
                options={props?.options?.map((option: any) => option.label) || ['please select an option']}
                required={props.required}
                delimiter={props.delimiter}
                data-testid={`nexus-select${props.name ? '-' + props.name : ''}`}
                onTriggerSelection={(event: NexusSelectCustomEvent<string>) => {
                    const selectedOption = props.options.find((option: any) => option.label === event.target.value);
                    onChangeOpts(selectedOption);
                }}
                className='custom-select'
            ></NexusSelect>
        </div>
    );
};

export default Select;
