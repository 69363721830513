/* tslint:disable */
/* eslint-disable */
/**
 * Regulation Library Layer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AggregateObligationsByReasonStatusDTO
 */
export interface AggregateObligationsByReasonStatusDTO {
    /**
     * 
     * @type {Array<ReasonStatusCounts>}
     * @memberof AggregateObligationsByReasonStatusDTO
     */
    'obligationStatusReasonCounts'?: Array<ReasonStatusCounts>;
}
/**
 * 
 * @export
 * @interface AggregateObligationsByStatusDTO
 */
export interface AggregateObligationsByStatusDTO {
    /**
     * 
     * @type {Array<ObligationStatusCounts>}
     * @memberof AggregateObligationsByStatusDTO
     */
    'obligationStatusCounts'?: Array<ObligationStatusCounts>;
}
/**
 * 
 * @export
 * @interface AggregateRegulationsByStatusDTO
 */
export interface AggregateRegulationsByStatusDTO {
    /**
     * 
     * @type {Array<RegulationStatusCounts>}
     * @memberof AggregateRegulationsByStatusDTO
     */
    'regulationStatusCounts'?: Array<RegulationStatusCounts>;
}
/**
 * 
 * @export
 * @interface BusinessUnitDTO
 */
export interface BusinessUnitDTO {
    /**
     * 
     * @type {string}
     * @memberof BusinessUnitDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessUnitDTO
     */
    'code'?: string;
}
/**
 * The change Pageable
 * @export
 * @interface BusinessUnitPageDTO
 */
export interface BusinessUnitPageDTO {
    /**
     * 
     * @type {Array<BusinessUnitDTO>}
     * @memberof BusinessUnitPageDTO
     */
    'content'?: Array<BusinessUnitDTO>;
    /**
     * is it first page 
     * @type {boolean}
     * @memberof BusinessUnitPageDTO
     */
    'first'?: boolean;
    /**
     * is it last page 
     * @type {boolean}
     * @memberof BusinessUnitPageDTO
     */
    'last'?: boolean;
    /**
     * How many pages in total
     * @type {number}
     * @memberof BusinessUnitPageDTO
     */
    'totalPages'?: number;
    /**
     * How many elements in total for searched criteria
     * @type {number}
     * @memberof BusinessUnitPageDTO
     */
    'totalElements'?: number;
    /**
     * current page number
     * @type {number}
     * @memberof BusinessUnitPageDTO
     */
    'number'?: number;
    /**
     * How many elements per page are there
     * @type {number}
     * @memberof BusinessUnitPageDTO
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface CommentDTO
 */
export interface CommentDTO {
    /**
     * 
     * @type {string}
     * @memberof CommentDTO
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDTO
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDTO
     */
    'createdAt'?: string;
}
/**
 * The change Pageable
 * @export
 * @interface CommentPageDTO
 */
export interface CommentPageDTO {
    /**
     * 
     * @type {Array<CommentDTO>}
     * @memberof CommentPageDTO
     */
    'content'?: Array<CommentDTO>;
    /**
     * is it first page 
     * @type {boolean}
     * @memberof CommentPageDTO
     */
    'first'?: boolean;
    /**
     * is it last page 
     * @type {boolean}
     * @memberof CommentPageDTO
     */
    'last'?: boolean;
    /**
     * How many pages in total
     * @type {number}
     * @memberof CommentPageDTO
     */
    'totalPages'?: number;
    /**
     * How many elements in total for searched criteria
     * @type {number}
     * @memberof CommentPageDTO
     */
    'totalElements'?: number;
    /**
     * current page number
     * @type {number}
     * @memberof CommentPageDTO
     */
    'number'?: number;
    /**
     * How many elements per page are there
     * @type {number}
     * @memberof CommentPageDTO
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ComplianceStatus = {
    New: 'NEW',
    Compliant: 'COMPLIANT',
    NotCompliant: 'NOT_COMPLIANT',
    NonApplicable: 'NON_APPLICABLE',
    PendingForReview: 'PENDING_FOR_REVIEW'
} as const;

export type ComplianceStatus = typeof ComplianceStatus[keyof typeof ComplianceStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ComplianceStatusReasonType = {
    NoRelationToUs: 'NO_RELATION_TO_US',
    MissingFile: 'MISSING_FILE',
    ContingentLiability: 'CONTINGENT_LIABILITY'
} as const;

export type ComplianceStatusReasonType = typeof ComplianceStatusReasonType[keyof typeof ComplianceStatusReasonType];


/**
 * 
 * @export
 * @interface CreateCommentDTO
 */
export interface CreateCommentDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateCommentDTO
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCommentDTO
     */
    'userId'?: string;
}
/**
 * 
 * @export
 * @interface JurisdictionDTO
 */
export interface JurisdictionDTO {
    /**
     * 
     * @type {string}
     * @memberof JurisdictionDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof JurisdictionDTO
     */
    'code'?: string;
}
/**
 * The change Pageable
 * @export
 * @interface JurisdictionPageDTO
 */
export interface JurisdictionPageDTO {
    /**
     * 
     * @type {Array<JurisdictionDTO>}
     * @memberof JurisdictionPageDTO
     */
    'content'?: Array<JurisdictionDTO>;
    /**
     * is it first page 
     * @type {boolean}
     * @memberof JurisdictionPageDTO
     */
    'first'?: boolean;
    /**
     * is it last page 
     * @type {boolean}
     * @memberof JurisdictionPageDTO
     */
    'last'?: boolean;
    /**
     * How many pages in total
     * @type {number}
     * @memberof JurisdictionPageDTO
     */
    'totalPages'?: number;
    /**
     * How many elements in total for searched criteria
     * @type {number}
     * @memberof JurisdictionPageDTO
     */
    'totalElements'?: number;
    /**
     * current page number
     * @type {number}
     * @memberof JurisdictionPageDTO
     */
    'number'?: number;
    /**
     * How many elements per page are there
     * @type {number}
     * @memberof JurisdictionPageDTO
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface ObligationDTO
 */
export interface ObligationDTO {
    /**
     * 
     * @type {string}
     * @memberof ObligationDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObligationDTO
     */
    'regulationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObligationDTO
     */
    'regulationName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObligationDTO
     */
    'sectionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObligationDTO
     */
    'sectionHeader'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObligationDTO
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObligationDTO
     */
    'simplifiedSummary'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObligationDTO
     */
    'lastUpdatedDate'?: string;
    /**
     * 
     * @type {ComplianceStatus}
     * @memberof ObligationDTO
     */
    'complianceStatus'?: ComplianceStatus;
    /**
     * 
     * @type {string}
     * @memberof ObligationDTO
     */
    'businessNumber'?: string;
    /**
     * 
     * @type {Array<UserDTO>}
     * @memberof ObligationDTO
     */
    'users'?: Array<UserDTO>;
    /**
     * 
     * @type {Array<TagType>}
     * @memberof ObligationDTO
     */
    'tags'?: Array<TagType>;
}


/**
 * 
 * @export
 * @interface ObligationSearchCriteriaDTO
 */
export interface ObligationSearchCriteriaDTO {
    /**
     * 
     * @type {string}
     * @memberof ObligationSearchCriteriaDTO
     */
    'sectionId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObligationSearchCriteriaDTO
     */
    'regulationIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObligationSearchCriteriaDTO
     */
    'jurisdictions'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObligationSearchCriteriaDTO
     */
    'businessUnits'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObligationSearchCriteriaDTO
     */
    'userIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ObligationStatusCounts
 */
export interface ObligationStatusCounts {
    /**
     * 
     * @type {number}
     * @memberof ObligationStatusCounts
     */
    'count'?: number;
    /**
     * 
     * @type {ComplianceStatus}
     * @memberof ObligationStatusCounts
     */
    'status'?: ComplianceStatus;
}


/**
 * 
 * @export
 * @interface ObligationStatusDTO
 */
export interface ObligationStatusDTO {
    /**
     * 
     * @type {ComplianceStatus}
     * @memberof ObligationStatusDTO
     */
    'status'?: ComplianceStatus;
}


/**
 * Page object containing objects
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * Searched content
     * @type {Array<object>}
     * @memberof Page
     */
    'content'?: Array<object>;
    /**
     * is it first page 
     * @type {boolean}
     * @memberof Page
     */
    'first'?: boolean;
    /**
     * is it last page 
     * @type {boolean}
     * @memberof Page
     */
    'last'?: boolean;
    /**
     * How many pages in total
     * @type {number}
     * @memberof Page
     */
    'totalPages'?: number;
    /**
     * How many elements in total for searched criteria
     * @type {number}
     * @memberof Page
     */
    'totalElements'?: number;
    /**
     * current page number
     * @type {number}
     * @memberof Page
     */
    'number'?: number;
    /**
     * How many elements per page are there
     * @type {number}
     * @memberof Page
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface ReasonStatusCounts
 */
export interface ReasonStatusCounts {
    /**
     * 
     * @type {number}
     * @memberof ReasonStatusCounts
     */
    'count'?: number;
    /**
     * 
     * @type {ComplianceStatusReasonType}
     * @memberof ReasonStatusCounts
     */
    'statusReasonType'?: ComplianceStatusReasonType;
}


/**
 * 
 * @export
 * @interface RegulationDTO
 */
export interface RegulationDTO {
    /**
     * 
     * @type {string}
     * @memberof RegulationDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegulationDTO
     */
    'name'?: string;
}
/**
 * The change Pageable
 * @export
 * @interface RegulationPageDTO
 */
export interface RegulationPageDTO {
    /**
     * 
     * @type {Array<RegulationDTO>}
     * @memberof RegulationPageDTO
     */
    'content'?: Array<RegulationDTO>;
    /**
     * is it first page 
     * @type {boolean}
     * @memberof RegulationPageDTO
     */
    'first'?: boolean;
    /**
     * is it last page 
     * @type {boolean}
     * @memberof RegulationPageDTO
     */
    'last'?: boolean;
    /**
     * How many pages in total
     * @type {number}
     * @memberof RegulationPageDTO
     */
    'totalPages'?: number;
    /**
     * How many elements in total for searched criteria
     * @type {number}
     * @memberof RegulationPageDTO
     */
    'totalElements'?: number;
    /**
     * current page number
     * @type {number}
     * @memberof RegulationPageDTO
     */
    'number'?: number;
    /**
     * How many elements per page are there
     * @type {number}
     * @memberof RegulationPageDTO
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RegulationStatus = {
    Compliant: 'COMPLIANT',
    PartiallyCompliant: 'PARTIALLY_COMPLIANT',
    NonCompliant: 'NON_COMPLIANT'
} as const;

export type RegulationStatus = typeof RegulationStatus[keyof typeof RegulationStatus];


/**
 * 
 * @export
 * @interface RegulationStatusCounts
 */
export interface RegulationStatusCounts {
    /**
     * 
     * @type {number}
     * @memberof RegulationStatusCounts
     */
    'count'?: number;
    /**
     * 
     * @type {RegulationStatus}
     * @memberof RegulationStatusCounts
     */
    'status'?: RegulationStatus;
}


/**
 * 
 * @export
 * @interface SectionDTO
 */
export interface SectionDTO {
    /**
     * 
     * @type {string}
     * @memberof SectionDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionDTO
     */
    'header'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionDTO
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionDTO
     */
    'regulationDateEffective'?: string;
    /**
     * 
     * @type {SectionStatus}
     * @memberof SectionDTO
     */
    'status'?: SectionStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SectionStatus = {
    Compliant: 'COMPLIANT',
    PartiallyCompliant: 'PARTIALLY_COMPLIANT',
    NonCompliant: 'NON_COMPLIANT'
} as const;

export type SectionStatus = typeof SectionStatus[keyof typeof SectionStatus];


/**
 * 
 * @export
 * @interface SimpleObligationDTO
 */
export interface SimpleObligationDTO {
    /**
     * 
     * @type {string}
     * @memberof SimpleObligationDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleObligationDTO
     */
    'summary'?: string;
    /**
     * 
     * @type {ComplianceStatus}
     * @memberof SimpleObligationDTO
     */
    'complianceStatus'?: ComplianceStatus;
    /**
     * 
     * @type {string}
     * @memberof SimpleObligationDTO
     */
    'businessNumber'?: string;
}


/**
 * The change Pageable
 * @export
 * @interface SimpleObligationPageDTO
 */
export interface SimpleObligationPageDTO {
    /**
     * 
     * @type {Array<SimpleObligationDTO>}
     * @memberof SimpleObligationPageDTO
     */
    'content'?: Array<SimpleObligationDTO>;
    /**
     * is it first page 
     * @type {boolean}
     * @memberof SimpleObligationPageDTO
     */
    'first'?: boolean;
    /**
     * is it last page 
     * @type {boolean}
     * @memberof SimpleObligationPageDTO
     */
    'last'?: boolean;
    /**
     * How many pages in total
     * @type {number}
     * @memberof SimpleObligationPageDTO
     */
    'totalPages'?: number;
    /**
     * How many elements in total for searched criteria
     * @type {number}
     * @memberof SimpleObligationPageDTO
     */
    'totalElements'?: number;
    /**
     * current page number
     * @type {number}
     * @memberof SimpleObligationPageDTO
     */
    'number'?: number;
    /**
     * How many elements per page are there
     * @type {number}
     * @memberof SimpleObligationPageDTO
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface SimpleSectionDTO
 */
export interface SimpleSectionDTO {
    /**
     * 
     * @type {string}
     * @memberof SimpleSectionDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleSectionDTO
     */
    'header'?: string;
    /**
     * 
     * @type {SectionStatus}
     * @memberof SimpleSectionDTO
     */
    'status'?: SectionStatus;
}


/**
 * The change Pageable
 * @export
 * @interface SimpleSectionPageDTO
 */
export interface SimpleSectionPageDTO {
    /**
     * 
     * @type {Array<SimpleSectionDTO>}
     * @memberof SimpleSectionPageDTO
     */
    'content'?: Array<SimpleSectionDTO>;
    /**
     * is it first page 
     * @type {boolean}
     * @memberof SimpleSectionPageDTO
     */
    'first'?: boolean;
    /**
     * is it last page 
     * @type {boolean}
     * @memberof SimpleSectionPageDTO
     */
    'last'?: boolean;
    /**
     * How many pages in total
     * @type {number}
     * @memberof SimpleSectionPageDTO
     */
    'totalPages'?: number;
    /**
     * How many elements in total for searched criteria
     * @type {number}
     * @memberof SimpleSectionPageDTO
     */
    'totalElements'?: number;
    /**
     * current page number
     * @type {number}
     * @memberof SimpleSectionPageDTO
     */
    'number'?: number;
    /**
     * How many elements per page are there
     * @type {number}
     * @memberof SimpleSectionPageDTO
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TagType = {
    SystemChange: 'SYSTEM_CHANGE',
    DocReviewUpload: 'DOC_REVIEW_UPLOAD',
    PolicyReview: 'POLICY_REVIEW'
} as const;

export type TagType = typeof TagType[keyof typeof TagType];


/**
 * 
 * @export
 * @interface UpdateObligationDTO
 */
export interface UpdateObligationDTO {
    /**
     * 
     * @type {ComplianceStatus}
     * @memberof UpdateObligationDTO
     */
    'complianceStatus'?: ComplianceStatus;
    /**
     * 
     * @type {ComplianceStatusReasonType}
     * @memberof UpdateObligationDTO
     */
    'complianceStatusReasonType'?: ComplianceStatusReasonType;
    /**
     * 
     * @type {string}
     * @memberof UpdateObligationDTO
     */
    'reasonText'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateObligationDTO
     */
    'lastUpdateDate'?: string;
    /**
     * 
     * @type {Array<TagType>}
     * @memberof UpdateObligationDTO
     */
    'tags'?: Array<TagType>;
    /**
     * 
     * @type {Array<UserDTO>}
     * @memberof UpdateObligationDTO
     */
    'owners'?: Array<UserDTO>;
}


/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'name'?: string;
    /**
     * 
     * @type {UserType}
     * @memberof UserDTO
     */
    'type'?: UserType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const UserType = {
    Primary: 'PRIMARY',
    Secondary: 'SECONDARY'
} as const;

export type UserType = typeof UserType[keyof typeof UserType];



/**
 * BusinessUnitResourceApi - axios parameter creator
 * @export
 */
export const BusinessUnitResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get list of business units
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessUnits: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/businessUnits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessUnitResourceApi - functional programming interface
 * @export
 */
export const BusinessUnitResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BusinessUnitResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * get list of business units
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessUnits(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitPageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnits(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BusinessUnitResourceApi - factory interface
 * @export
 */
export const BusinessUnitResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BusinessUnitResourceApiFp(configuration)
    return {
        /**
         * get list of business units
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessUnits(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<BusinessUnitPageDTO> {
            return localVarFp.getBusinessUnits(page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BusinessUnitResourceApi - object-oriented interface
 * @export
 * @class BusinessUnitResourceApi
 * @extends {BaseAPI}
 */
export class BusinessUnitResourceApi extends BaseAPI {
    /**
     * get list of business units
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned. Minimum value: 1
     * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessUnitResourceApi
     */
    public getBusinessUnits(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return BusinessUnitResourceApiFp(this.configuration).getBusinessUnits(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * JurisdictionResourceApi - axios parameter creator
 * @export
 */
export const JurisdictionResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get list of jurisdictions
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJurisdictions: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/jurisdictions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JurisdictionResourceApi - functional programming interface
 * @export
 */
export const JurisdictionResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JurisdictionResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * get list of jurisdictions
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJurisdictions(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JurisdictionPageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJurisdictions(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JurisdictionResourceApi - factory interface
 * @export
 */
export const JurisdictionResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JurisdictionResourceApiFp(configuration)
    return {
        /**
         * get list of jurisdictions
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJurisdictions(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<JurisdictionPageDTO> {
            return localVarFp.getJurisdictions(page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JurisdictionResourceApi - object-oriented interface
 * @export
 * @class JurisdictionResourceApi
 * @extends {BaseAPI}
 */
export class JurisdictionResourceApi extends BaseAPI {
    /**
     * get list of jurisdictions
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned. Minimum value: 1
     * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JurisdictionResourceApi
     */
    public getJurisdictions(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return JurisdictionResourceApiFp(this.configuration).getJurisdictions(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ObligationResourceApi - axios parameter creator
 * @export
 */
export const ObligationResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * assign primary and secondary contact
         * @param {string} regulationId 
         * @param {Array<UserDTO>} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignContacts: async (regulationId: string, userDTO: Array<UserDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regulationId' is not null or undefined
            assertParamExists('assignContacts', 'regulationId', regulationId)
            // verify required parameter 'userDTO' is not null or undefined
            assertParamExists('assignContacts', 'userDTO', userDTO)
            const localVarPath = `/obligations/assign-contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (regulationId !== undefined) {
                localVarQueryParameter['regulationId'] = regulationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create comment for obligationId
         * @param {string} obligationId 
         * @param {CreateCommentDTO} createCommentDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment: async (obligationId: string, createCommentDTO: CreateCommentDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'obligationId' is not null or undefined
            assertParamExists('createComment', 'obligationId', obligationId)
            // verify required parameter 'createCommentDTO' is not null or undefined
            assertParamExists('createComment', 'createCommentDTO', createCommentDTO)
            const localVarPath = `/obligations/{obligationId}/comments`
                .replace(`{${"obligationId"}}`, encodeURIComponent(String(obligationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCommentDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get list of all comments for obligationId
         * @param {string} obligationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments: async (obligationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'obligationId' is not null or undefined
            assertParamExists('getComments', 'obligationId', obligationId)
            const localVarPath = `/obligations/{obligationId}/comments`
                .replace(`{${"obligationId"}}`, encodeURIComponent(String(obligationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get details for the obligationId
         * @param {string} obligationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObligation: async (obligationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'obligationId' is not null or undefined
            assertParamExists('getObligation', 'obligationId', obligationId)
            const localVarPath = `/obligations/{obligationId}`
                .replace(`{${"obligationId"}}`, encodeURIComponent(String(obligationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get list of obligations for section
         * @param {string} sectionId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObligations: async (sectionId: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sectionId' is not null or undefined
            assertParamExists('getObligations', 'sectionId', sectionId)
            const localVarPath = `/obligations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sectionId !== undefined) {
                localVarQueryParameter['sectionId'] = sectionId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get obligations by reason status for particular status
         * @param {ComplianceStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObligationsByReasonStatus: async (status: ComplianceStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'status' is not null or undefined
            assertParamExists('getObligationsByReasonStatus', 'status', status)
            const localVarPath = `/obligations-by-reason-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get list of regulations by search criteria
         * @param {ObligationSearchCriteriaDTO} obligationSearchCriteriaDTO 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObligationsBySearchCriteria: async (obligationSearchCriteriaDTO: ObligationSearchCriteriaDTO, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'obligationSearchCriteriaDTO' is not null or undefined
            assertParamExists('getObligationsBySearchCriteria', 'obligationSearchCriteriaDTO', obligationSearchCriteriaDTO)
            const localVarPath = `/obligations/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(obligationSearchCriteriaDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get obligations by calculated status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObligationsByStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/obligations-by-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get obligations by calculated status filtered by search criteria
         * @param {ObligationSearchCriteriaDTO} obligationSearchCriteriaDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObligationsByStatusWithSearchCriteria: async (obligationSearchCriteriaDTO: ObligationSearchCriteriaDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'obligationSearchCriteriaDTO' is not null or undefined
            assertParamExists('getObligationsByStatusWithSearchCriteria', 'obligationSearchCriteriaDTO', obligationSearchCriteriaDTO)
            const localVarPath = `/obligations-by-status/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(obligationSearchCriteriaDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update obligation
         * @param {string} obligationId 
         * @param {UpdateObligationDTO} updateObligationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateObligation: async (obligationId: string, updateObligationDTO: UpdateObligationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'obligationId' is not null or undefined
            assertParamExists('updateObligation', 'obligationId', obligationId)
            // verify required parameter 'updateObligationDTO' is not null or undefined
            assertParamExists('updateObligation', 'updateObligationDTO', updateObligationDTO)
            const localVarPath = `/obligations/{obligationId}`
                .replace(`{${"obligationId"}}`, encodeURIComponent(String(obligationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateObligationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ObligationResourceApi - functional programming interface
 * @export
 */
export const ObligationResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ObligationResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * assign primary and secondary contact
         * @param {string} regulationId 
         * @param {Array<UserDTO>} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignContacts(regulationId: string, userDTO: Array<UserDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignContacts(regulationId, userDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create comment for obligationId
         * @param {string} obligationId 
         * @param {CreateCommentDTO} createCommentDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComment(obligationId: string, createCommentDTO: CreateCommentDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createComment(obligationId, createCommentDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get list of all comments for obligationId
         * @param {string} obligationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComments(obligationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentPageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComments(obligationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get details for the obligationId
         * @param {string} obligationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getObligation(obligationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObligationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getObligation(obligationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get list of obligations for section
         * @param {string} sectionId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getObligations(sectionId: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleObligationPageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getObligations(sectionId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get obligations by reason status for particular status
         * @param {ComplianceStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getObligationsByReasonStatus(status: ComplianceStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateObligationsByReasonStatusDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getObligationsByReasonStatus(status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get list of regulations by search criteria
         * @param {ObligationSearchCriteriaDTO} obligationSearchCriteriaDTO 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getObligationsBySearchCriteria(obligationSearchCriteriaDTO: ObligationSearchCriteriaDTO, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleObligationPageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getObligationsBySearchCriteria(obligationSearchCriteriaDTO, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get obligations by calculated status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getObligationsByStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateObligationsByStatusDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getObligationsByStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get obligations by calculated status filtered by search criteria
         * @param {ObligationSearchCriteriaDTO} obligationSearchCriteriaDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getObligationsByStatusWithSearchCriteria(obligationSearchCriteriaDTO: ObligationSearchCriteriaDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateObligationsByStatusDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getObligationsByStatusWithSearchCriteria(obligationSearchCriteriaDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update obligation
         * @param {string} obligationId 
         * @param {UpdateObligationDTO} updateObligationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateObligation(obligationId: string, updateObligationDTO: UpdateObligationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateObligation(obligationId, updateObligationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ObligationResourceApi - factory interface
 * @export
 */
export const ObligationResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ObligationResourceApiFp(configuration)
    return {
        /**
         * assign primary and secondary contact
         * @param {string} regulationId 
         * @param {Array<UserDTO>} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignContacts(regulationId: string, userDTO: Array<UserDTO>, options?: any): AxiosPromise<string> {
            return localVarFp.assignContacts(regulationId, userDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * create comment for obligationId
         * @param {string} obligationId 
         * @param {CreateCommentDTO} createCommentDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment(obligationId: string, createCommentDTO: CreateCommentDTO, options?: any): AxiosPromise<void> {
            return localVarFp.createComment(obligationId, createCommentDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * get list of all comments for obligationId
         * @param {string} obligationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments(obligationId: string, options?: any): AxiosPromise<CommentPageDTO> {
            return localVarFp.getComments(obligationId, options).then((request) => request(axios, basePath));
        },
        /**
         * get details for the obligationId
         * @param {string} obligationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObligation(obligationId: string, options?: any): AxiosPromise<ObligationDTO> {
            return localVarFp.getObligation(obligationId, options).then((request) => request(axios, basePath));
        },
        /**
         * get list of obligations for section
         * @param {string} sectionId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObligations(sectionId: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<SimpleObligationPageDTO> {
            return localVarFp.getObligations(sectionId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * get obligations by reason status for particular status
         * @param {ComplianceStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObligationsByReasonStatus(status: ComplianceStatus, options?: any): AxiosPromise<AggregateObligationsByReasonStatusDTO> {
            return localVarFp.getObligationsByReasonStatus(status, options).then((request) => request(axios, basePath));
        },
        /**
         * get list of regulations by search criteria
         * @param {ObligationSearchCriteriaDTO} obligationSearchCriteriaDTO 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObligationsBySearchCriteria(obligationSearchCriteriaDTO: ObligationSearchCriteriaDTO, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<SimpleObligationPageDTO> {
            return localVarFp.getObligationsBySearchCriteria(obligationSearchCriteriaDTO, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * get obligations by calculated status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObligationsByStatus(options?: any): AxiosPromise<AggregateObligationsByStatusDTO> {
            return localVarFp.getObligationsByStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * get obligations by calculated status filtered by search criteria
         * @param {ObligationSearchCriteriaDTO} obligationSearchCriteriaDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObligationsByStatusWithSearchCriteria(obligationSearchCriteriaDTO: ObligationSearchCriteriaDTO, options?: any): AxiosPromise<AggregateObligationsByStatusDTO> {
            return localVarFp.getObligationsByStatusWithSearchCriteria(obligationSearchCriteriaDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * update obligation
         * @param {string} obligationId 
         * @param {UpdateObligationDTO} updateObligationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateObligation(obligationId: string, updateObligationDTO: UpdateObligationDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateObligation(obligationId, updateObligationDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ObligationResourceApi - object-oriented interface
 * @export
 * @class ObligationResourceApi
 * @extends {BaseAPI}
 */
export class ObligationResourceApi extends BaseAPI {
    /**
     * assign primary and secondary contact
     * @param {string} regulationId 
     * @param {Array<UserDTO>} userDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObligationResourceApi
     */
    public assignContacts(regulationId: string, userDTO: Array<UserDTO>, options?: AxiosRequestConfig) {
        return ObligationResourceApiFp(this.configuration).assignContacts(regulationId, userDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create comment for obligationId
     * @param {string} obligationId 
     * @param {CreateCommentDTO} createCommentDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObligationResourceApi
     */
    public createComment(obligationId: string, createCommentDTO: CreateCommentDTO, options?: AxiosRequestConfig) {
        return ObligationResourceApiFp(this.configuration).createComment(obligationId, createCommentDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get list of all comments for obligationId
     * @param {string} obligationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObligationResourceApi
     */
    public getComments(obligationId: string, options?: AxiosRequestConfig) {
        return ObligationResourceApiFp(this.configuration).getComments(obligationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get details for the obligationId
     * @param {string} obligationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObligationResourceApi
     */
    public getObligation(obligationId: string, options?: AxiosRequestConfig) {
        return ObligationResourceApiFp(this.configuration).getObligation(obligationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get list of obligations for section
     * @param {string} sectionId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned. Minimum value: 1
     * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObligationResourceApi
     */
    public getObligations(sectionId: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return ObligationResourceApiFp(this.configuration).getObligations(sectionId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get obligations by reason status for particular status
     * @param {ComplianceStatus} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObligationResourceApi
     */
    public getObligationsByReasonStatus(status: ComplianceStatus, options?: AxiosRequestConfig) {
        return ObligationResourceApiFp(this.configuration).getObligationsByReasonStatus(status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get list of regulations by search criteria
     * @param {ObligationSearchCriteriaDTO} obligationSearchCriteriaDTO 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned. Minimum value: 1
     * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObligationResourceApi
     */
    public getObligationsBySearchCriteria(obligationSearchCriteriaDTO: ObligationSearchCriteriaDTO, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return ObligationResourceApiFp(this.configuration).getObligationsBySearchCriteria(obligationSearchCriteriaDTO, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get obligations by calculated status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObligationResourceApi
     */
    public getObligationsByStatus(options?: AxiosRequestConfig) {
        return ObligationResourceApiFp(this.configuration).getObligationsByStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get obligations by calculated status filtered by search criteria
     * @param {ObligationSearchCriteriaDTO} obligationSearchCriteriaDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObligationResourceApi
     */
    public getObligationsByStatusWithSearchCriteria(obligationSearchCriteriaDTO: ObligationSearchCriteriaDTO, options?: AxiosRequestConfig) {
        return ObligationResourceApiFp(this.configuration).getObligationsByStatusWithSearchCriteria(obligationSearchCriteriaDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update obligation
     * @param {string} obligationId 
     * @param {UpdateObligationDTO} updateObligationDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObligationResourceApi
     */
    public updateObligation(obligationId: string, updateObligationDTO: UpdateObligationDTO, options?: AxiosRequestConfig) {
        return ObligationResourceApiFp(this.configuration).updateObligation(obligationId, updateObligationDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RegulationResourceApi - axios parameter creator
 * @export
 */
export const RegulationResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get last updated Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastUpdatedDate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/regulations/last-updated-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get list of Regulations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegulations: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/regulations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get regulations by calculated status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegulationsByStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/regulations-by-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * load data application local storage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/regulations/loadData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegulationResourceApi - functional programming interface
 * @export
 */
export const RegulationResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegulationResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * get last updated Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastUpdatedDate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastUpdatedDate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get list of Regulations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegulations(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegulationPageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegulations(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get regulations by calculated status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegulationsByStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateRegulationsByStatusDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegulationsByStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * load data application local storage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegulationResourceApi - factory interface
 * @export
 */
export const RegulationResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegulationResourceApiFp(configuration)
    return {
        /**
         * get last updated Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastUpdatedDate(options?: any): AxiosPromise<string> {
            return localVarFp.getLastUpdatedDate(options).then((request) => request(axios, basePath));
        },
        /**
         * get list of Regulations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegulations(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<RegulationPageDTO> {
            return localVarFp.getRegulations(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * get regulations by calculated status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegulationsByStatus(options?: any): AxiosPromise<AggregateRegulationsByStatusDTO> {
            return localVarFp.getRegulationsByStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * load data application local storage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadData(options?: any): AxiosPromise<void> {
            return localVarFp.loadData(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegulationResourceApi - object-oriented interface
 * @export
 * @class RegulationResourceApi
 * @extends {BaseAPI}
 */
export class RegulationResourceApi extends BaseAPI {
    /**
     * get last updated Date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegulationResourceApi
     */
    public getLastUpdatedDate(options?: AxiosRequestConfig) {
        return RegulationResourceApiFp(this.configuration).getLastUpdatedDate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get list of Regulations
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned. Minimum value: 1
     * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegulationResourceApi
     */
    public getRegulations(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return RegulationResourceApiFp(this.configuration).getRegulations(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get regulations by calculated status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegulationResourceApi
     */
    public getRegulationsByStatus(options?: AxiosRequestConfig) {
        return RegulationResourceApiFp(this.configuration).getRegulationsByStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * load data application local storage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegulationResourceApi
     */
    public loadData(options?: AxiosRequestConfig) {
        return RegulationResourceApiFp(this.configuration).loadData(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SectionResourceApi - axios parameter creator
 * @export
 */
export const SectionResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all sections
         * @param {string} [regulationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSections: async (regulationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (regulationId !== undefined) {
                localVarQueryParameter['regulationId'] = regulationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get section by sectionId
         * @param {string} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSection: async (sectionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sectionId' is not null or undefined
            assertParamExists('getSection', 'sectionId', sectionId)
            const localVarPath = `/sections/{sectionId}`
                .replace(`{${"sectionId"}}`, encodeURIComponent(String(sectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get list of sections by search criteria
         * @param {ObligationSearchCriteriaDTO} obligationSearchCriteriaDTO 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectionsBySearchCriteria: async (obligationSearchCriteriaDTO: ObligationSearchCriteriaDTO, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'obligationSearchCriteriaDTO' is not null or undefined
            assertParamExists('getSectionsBySearchCriteria', 'obligationSearchCriteriaDTO', obligationSearchCriteriaDTO)
            const localVarPath = `/sections/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(obligationSearchCriteriaDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SectionResourceApi - functional programming interface
 * @export
 */
export const SectionResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SectionResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all sections
         * @param {string} [regulationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSections(regulationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleSectionPageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSections(regulationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get section by sectionId
         * @param {string} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSection(sectionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSection(sectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get list of sections by search criteria
         * @param {ObligationSearchCriteriaDTO} obligationSearchCriteriaDTO 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSectionsBySearchCriteria(obligationSearchCriteriaDTO: ObligationSearchCriteriaDTO, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleSectionPageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSectionsBySearchCriteria(obligationSearchCriteriaDTO, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SectionResourceApi - factory interface
 * @export
 */
export const SectionResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SectionResourceApiFp(configuration)
    return {
        /**
         * Get all sections
         * @param {string} [regulationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSections(regulationId?: string, options?: any): AxiosPromise<SimpleSectionPageDTO> {
            return localVarFp.getAllSections(regulationId, options).then((request) => request(axios, basePath));
        },
        /**
         * get section by sectionId
         * @param {string} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSection(sectionId: string, options?: any): AxiosPromise<SectionDTO> {
            return localVarFp.getSection(sectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * get list of sections by search criteria
         * @param {ObligationSearchCriteriaDTO} obligationSearchCriteriaDTO 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned. Minimum value: 1
         * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectionsBySearchCriteria(obligationSearchCriteriaDTO: ObligationSearchCriteriaDTO, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<SimpleSectionPageDTO> {
            return localVarFp.getSectionsBySearchCriteria(obligationSearchCriteriaDTO, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SectionResourceApi - object-oriented interface
 * @export
 * @class SectionResourceApi
 * @extends {BaseAPI}
 */
export class SectionResourceApi extends BaseAPI {
    /**
     * Get all sections
     * @param {string} [regulationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionResourceApi
     */
    public getAllSections(regulationId?: string, options?: AxiosRequestConfig) {
        return SectionResourceApiFp(this.configuration).getAllSections(regulationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get section by sectionId
     * @param {string} sectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionResourceApi
     */
    public getSection(sectionId: string, options?: AxiosRequestConfig) {
        return SectionResourceApiFp(this.configuration).getSection(sectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get list of sections by search criteria
     * @param {ObligationSearchCriteriaDTO} obligationSearchCriteriaDTO 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned. Minimum value: 1
     * @param {Array<string>} [sort] Sorting criteria in the format: propertyName,(desc|asc). Multiple sort criteria are supported
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionResourceApi
     */
    public getSectionsBySearchCriteria(obligationSearchCriteriaDTO: ObligationSearchCriteriaDTO, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return SectionResourceApiFp(this.configuration).getSectionsBySearchCriteria(obligationSearchCriteriaDTO, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}



