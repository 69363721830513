import { NexusLoader } from '@nexus/react';
import React from 'react';

interface Props {
    show: boolean;
    fullscreen?: boolean;
    classes?: string;
}
const LoaderComponent: React.FC<Props> = ({ show, fullscreen, classes }) => (
    <div className='loader-index'>
        <NexusLoader
            className={classes ?? ''}
            data-testid='nexus-loader'
            show={show}
            fullscreen={fullscreen}
        ></NexusLoader>
    </div>
);

export default LoaderComponent;
