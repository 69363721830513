import { AuthContainer, UnAuthContainer } from '../containers';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import Dashboard from 'app/views/dashboard';
import LoginPage from '../views/login/loginPage';
import Logout from '../views/logout/logout';
import PageNotFound from '../views/404/404';
import ProfilePage from '../views/profile/profile';
import Inventory from 'app/views/Inventory/Inventory';

const AppRoutes = () => (
    <Router>
        <Routes>
            {/* New Dashboard */}
            <Route
                path='/dashboard'
                element={
                    <AuthContainer>
                        <Dashboard />
                    </AuthContainer>
                }
            />
            <Route
                path='/inventory/:type'
                element={
                    <AuthContainer>
                        <Inventory />
                    </AuthContainer>
                }
            />
            <Route
                path='/profile'
                element={
                    <AuthContainer>
                        <ProfilePage />
                    </AuthContainer>
                }
            />

            <Route
                path='/'
                element={
                    <UnAuthContainer>
                        <LoginPage />
                    </UnAuthContainer>
                }
            />

            <Route path='/logout' element={<Logout />} />

            <Route
                path='*'
                element={
                    <AuthContainer>
                        <PageNotFound />
                    </AuthContainer>
                }
            />
        </Routes>
    </Router>
);

export default AppRoutes;
