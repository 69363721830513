import { NexusCard } from '@nexus/react';
import React from 'react';
import MetricCard from '../metricsCards';
import './controlTab.scss';

function TabComponent({ cardsData = [], title = '' }: { cardsData: any[]; title: any }) {
    return (
        <div>
            <div className='control-tab-title'>{title}</div>
            <NexusCard className='control-tab-component'>
                {cardsData.map((card: any, index: number) => {
                    return <MetricCard key={index} {...card} />;
                })}
            </NexusCard>
        </div>
    );
}

export default TabComponent;
