import React, { useEffect } from 'react';
import AuthProvider from '../../../auth/authProvider';
import { deleteAllCookies } from 'utils/cookieUtils';

// abstracts the use of authprovider, may render content eventually
const Logout = () => {
    useEffect(() => {
        deleteAllCookies();
    }, []);
    return (
        <AuthProvider doLogout={true}>
            <></>
        </AuthProvider>
    );
};

export default Logout;
