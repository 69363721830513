/* tslint:disable */
/* eslint-disable */
/**
 * Control Rationalization Layer API
 * Control Rationalization API # Changelog  <details> <summary>Click to expand</summary> - 0.0 - Added GET /business-entities-by-metrics , /controls/metrics endpoint - Defined pagination components and entity schemas - 0.1 - Examples added for all endpoints
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AggregateBusinessEntityByMetricsDTO
 */
export interface AggregateBusinessEntityByMetricsDTO {
    /**
     *
     * @type {number}
     * @memberof AggregateBusinessEntityByMetricsDTO
     */
    totalCount?: number;
    /**
     *
     * @type {number}
     * @memberof AggregateBusinessEntityByMetricsDTO
     */
    metricTotalCount?: number;
    /**
     *
     * @type {number}
     * @memberof AggregateBusinessEntityByMetricsDTO
     */
    percentageMetricCount?: number;
    /**
     *
     * @type {AggregatedMetricStatus}
     * @memberof AggregateBusinessEntityByMetricsDTO
     */
    status?: AggregatedMetricStatus;
}

/**
 *
 * @export
 * @enum {string}
 */

export const AggregatedMetricStatus = {
    Good: 'GOOD',
    Significant: 'SIGNIFICANT',
    Critical: 'CRITICAL',
} as const;

export type AggregatedMetricStatus = typeof AggregatedMetricStatus[keyof typeof AggregatedMetricStatus];

/**
 *
 * @export
 * @enum {string}
 */

export const BusinessEntityType = {
    Risk: 'RISK',
    Control: 'CONTROL',
    Process: 'PROCESS',
} as const;

export type BusinessEntityType = typeof BusinessEntityType[keyof typeof BusinessEntityType];

/**
 *
 * @export
 * @enum {string}
 */

export const ControlMetric = {
    DuplicatedControls: 'DUPLICATED_CONTROLS',
    ControlToRiskLinkage: 'CONTROL_TO_RISK_LINKAGE',
    ManualControls: 'MANUAL_CONTROLS',
    ControlDescriptionQuality: 'CONTROL_DESCRIPTION_QUALITY',
} as const;

export type ControlMetric = typeof ControlMetric[keyof typeof ControlMetric];

/**
 *
 * @export
 * @interface ControlMetricDTO
 */
export interface ControlMetricDTO {
    /**
     *
     * @type {MetricStatus}
     * @memberof ControlMetricDTO
     */
    status?: MetricStatus;
    /**
     *
     * @type {number}
     * @memberof ControlMetricDTO
     */
    controlTotalCount?: number;
    /**
     *
     * @type {number}
     * @memberof ControlMetricDTO
     */
    totalCount?: number;
}

/**
 *
 * @export
 * @enum {string}
 */

export const MetricStatus = {
    Good: 'GOOD',
    Significant: 'SIGNIFICANT',
    Critical: 'CRITICAL',
} as const;

export type MetricStatus = typeof MetricStatus[keyof typeof MetricStatus];

/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     *
     * @type {string}
     * @memberof ModelError
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ModelError
     */
    title?: string;
    /**
     *
     * @type {number}
     * @memberof ModelError
     */
    status?: number;
    /**
     *
     * @type {string}
     * @memberof ModelError
     */
    detail?: string;
    /**
     *
     * @type {Error}
     * @memberof ModelError
     */
    cause?: Error;
}
/**
 * Page object containing objects
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * Searched content
     * @type {Array<object>}
     * @memberof Page
     */
    content?: Array<object>;
    /**
     * is it first page
     * @type {boolean}
     * @memberof Page
     */
    first?: boolean;
    /**
     * is it last page
     * @type {boolean}
     * @memberof Page
     */
    last?: boolean;
    /**
     * How many pages in total
     * @type {number}
     * @memberof Page
     */
    totalPages?: number;
    /**
     * How many elements in total for searched criteria
     * @type {number}
     * @memberof Page
     */
    totalElements?: number;
    /**
     * current page number
     * @type {number}
     * @memberof Page
     */
    number?: number;
    /**
     * How many elements per page are there
     * @type {number}
     * @memberof Page
     */
    size?: number;
}

/**
 * BusinessEntityResourceApi - axios parameter creator
 * @export
 */
export const BusinessEntityResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get a aggregation of business entities by different kpis
         * @param {BusinessEntityType} [businessEntityType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessEntitiesByKpi: async (
            businessEntityType?: BusinessEntityType,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/business-entities-by-metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (businessEntityType !== undefined) {
                localVarQueryParameter['businessEntityType'] = businessEntityType;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * BusinessEntityResourceApi - functional programming interface
 * @export
 */
export const BusinessEntityResourceApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = BusinessEntityResourceApiAxiosParamCreator(configuration);
    return {
        /**
         * Get a aggregation of business entities by different kpis
         * @param {BusinessEntityType} [businessEntityType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessEntitiesByKpi(
            businessEntityType?: BusinessEntityType,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateBusinessEntityByMetricsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessEntitiesByKpi(
                businessEntityType,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * BusinessEntityResourceApi - factory interface
 * @export
 */
export const BusinessEntityResourceApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = BusinessEntityResourceApiFp(configuration);
    return {
        /**
         * Get a aggregation of business entities by different kpis
         * @param {BusinessEntityType} [businessEntityType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessEntitiesByKpi(
            businessEntityType?: BusinessEntityType,
            options?: any,
        ): AxiosPromise<AggregateBusinessEntityByMetricsDTO> {
            return localVarFp
                .getBusinessEntitiesByKpi(businessEntityType, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * BusinessEntityResourceApi - object-oriented interface
 * @export
 * @class BusinessEntityResourceApi
 * @extends {BaseAPI}
 */
export class BusinessEntityResourceApi extends BaseAPI {
    /**
     * Get a aggregation of business entities by different kpis
     * @param {BusinessEntityType} [businessEntityType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessEntityResourceApi
     */
    public getBusinessEntitiesByKpi(businessEntityType?: BusinessEntityType, options?: AxiosRequestConfig) {
        return BusinessEntityResourceApiFp(this.configuration)
            .getBusinessEntitiesByKpi(businessEntityType, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * ControlResourceApi - axios parameter creator
 * @export
 */
export const ControlResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {ControlMetric} metricName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlMetrics: async (
            metricName: ControlMetric,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'metricName' is not null or undefined
            assertParamExists('getControlMetrics', 'metricName', metricName);
            const localVarPath = `/controls/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (metricName !== undefined) {
                localVarQueryParameter['metricName'] = metricName;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ControlResourceApi - functional programming interface
 * @export
 */
export const ControlResourceApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ControlResourceApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {ControlMetric} metricName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getControlMetrics(
            metricName: ControlMetric,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlMetricDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getControlMetrics(metricName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * ControlResourceApi - factory interface
 * @export
 */
export const ControlResourceApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = ControlResourceApiFp(configuration);
    return {
        /**
         *
         * @param {ControlMetric} metricName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlMetrics(metricName: ControlMetric, options?: any): AxiosPromise<ControlMetricDTO> {
            return localVarFp.getControlMetrics(metricName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ControlResourceApi - object-oriented interface
 * @export
 * @class ControlResourceApi
 * @extends {BaseAPI}
 */
export class ControlResourceApi extends BaseAPI {
    /**
     *
     * @param {ControlMetric} metricName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public getControlMetrics(metricName: ControlMetric, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration)
            .getControlMetrics(metricName, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
