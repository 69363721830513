import { AppDispatch, RootState } from '../store/index';
import {
    REACT_APP_API_DEBUG_LOGGING,
    REACT_APP_API_ERROR_LOGGING,
    REACT_APP_BASE_URL,
    REACT_APP_OCP_APIM_SUB_KEY,
    REACT_APP_REQUEST_TIMEOUT,
} from '../config/settings';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { formatRequestWithToken } from './tokenUtils';

import { CustomError } from 'constants/commonExportedInterfaces';
import { getLogger } from './logger';
import logHandler from './logHandler';
import { logout } from '../auth/authSlice';
import { forceCancel } from 'constants/constants';

// Initialize logger
const logger = getLogger();
logger.setLogHandler(logHandler);
const request =
    (method: string) =>
    ({ data = {}, baseURL = REACT_APP_BASE_URL, controller, headers, ...options }: any) => {
        return axios({
            baseURL,
            ...(controller && { signal: controller.signal }),
            data,
            timeout: REACT_APP_REQUEST_TIMEOUT || 100000,
            ...options,
            headers: {
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/json',
                ...headers,
                'Ocp-Apim-Subscription-Key': REACT_APP_OCP_APIM_SUB_KEY,
            },
            method,
            // Throw error for 404 - Not Found
            validateStatus: (status) => status !== 404,
        })
            .then((resp) => {
                // HTTP errors are returned in the response with client (400+) and server (500+) being errors
                if (resp.status >= 400) {
                    throw new CustomError({
                        detail: resp.data.detail,
                        message: resp.data.message,
                        title: resp.data.title,
                    });
                }
                return resp;
            })
            .catch((err) => {
                if (axios.isCancel(err)) {
                    throw new CustomError({
                        code: forceCancel,
                        detail: 'request cancelled due to a new request',
                        message: 'request cancelled due to a new request',
                        title: 'request cancelled due to a new request',
                    });
                } else if (err instanceof CustomError) {
                    throw new CustomError({
                        detail: err.detail,
                        message: err.message,
                        title: err.title,
                    });
                } else {
                    throw new Error();
                }
                // Non-standard errors
                // errorHandler(error, suppressMessaging);
            });
    };

// Utility method for wrapping log handling for API requests and responses
const log = (level: any, data: AxiosRequestConfig<any> | AxiosResponse<any, any>) => {
    const logData = logger.getLogDataFromRequestOrResponse(data);

    if (logData) {
        logger.log(level, logData);
    }
};

// Interceptors...

const setupRequestLoggingMiddleware = () => {
    axios.interceptors.request.use((req) => {
        log('debug', req);

        return req;
    });
};

const setupResponseErrorLoggingMiddleware = () => {
    axios.interceptors.response.use(
        // success handler
        (response) => {
            if (response.status >= 400) {
                log('error', response);
            }

            return response;
        },
        // error handler
        (error) => {
            log('error', error);

            throw error;
        },
    );
};

// Setup logging interceptor for API request/responses
if (REACT_APP_API_DEBUG_LOGGING) {
    setupRequestLoggingMiddleware();
}

if (REACT_APP_API_ERROR_LOGGING) {
    setupResponseErrorLoggingMiddleware();
}

// Export for external access to provide dispatch and state when called.
export const setupRequestAuthorizationMiddleware = () => (dispatch: AppDispatch, getState: RootState) => {
    axios.interceptors.request.use(async (req) => {
        if (req && req.headers && req.headers.shouldBypassAuthorizationRefresh) {
            delete req.headers.shouldBypassAuthorizationRefresh;

            return req;
        }

        const {
            auth: { accessToken, tokenExpiration, logoutPending },
        } = getState();

        if (logoutPending) {
            return req;
        }

        const now = Date.now();

        if (now > tokenExpiration) {
            // token has expired, trigger logout
            return dispatch(logout());
        }
        return formatRequestWithToken(req, accessToken);
    });
};

export const get = request('GET');
export const post = request('POST');
export const put = request('PUT');
export const patch = request('PATCH');
export const remove = request('DELETE');
