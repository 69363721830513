import React, { useEffect, useState } from 'react';
import './customSelect.scss';
import { NexusIcon } from '@nexus/react';
import EXPAND_LESS from '@nexus/core/dist/assets/icons/action/ic_expand_less_24px.svg';
import EXPAND_MORE from '@nexus/core/dist/assets/icons/action/ic_expand_more_24px.svg';

interface BaseProps {
    multiSelect?: boolean;
    type?: 'SELECT_1' | 'SELECT_2' | 'SELECT_3';
    displayElement?: any;
    onChange: (value: any, type: string) => void;
    className?: string;
    data: {
        id: any;
        optionLabel?: any;
        selectedLabel?: any;
        value: any;
    }[];
    closeIcon?: any;
    value?: any;
}

interface Type1 extends BaseProps {
    type: 'SELECT_1';
}
interface Type2 extends BaseProps {
    type: 'SELECT_2';
    displayElement: any;
}
interface Type3 extends BaseProps {
    type: 'SELECT_3';
}
type ConditionalType = Type1 | Type2 | Type3;

function CustomSelect({
    type = 'SELECT_1',
    displayElement,
    onChange,
    className,
    data,
    closeIcon,
    value,
}: ConditionalType) {
    const [open, setOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState<any[]>([]);
    const [selectedValue, setSelectedValue] = useState<any>();

    useEffect(() => {
        let selected;
        if (type === 'SELECT_1' || type === 'SELECT_3') {
            selected = data.find((item) => item?.value === value);
            setSelectedValues(selected ? [selected] : []);
        } else {
            setSelectedValues(value || []);
        }
    }, [value]);

    const onMultipleSelect = (value: any, type: string) => {
        let selections;
        if (selectedValues.find((item: any) => item?.id === value?.id)) {
            selections = selectedValues.filter((item: any) => item?.id !== value?.id);
        } else {
            selections = [...selectedValues, value];
        }
        onChange(selections, type);
        setSelectedValues(selections);
        setOpen(false);
    };

    const handleCloseClick = (item: any, type: string) => {
        const selections = selectedValues.filter((selectedItem) => selectedItem?.id !== item?.id);
        setSelectedValues(selections);
        onChange(selections, type);
    };

    const onSingleSelect = (item: any, type: string) => {
        const selected = data.find((dataItem) => dataItem.id === item.id);
        if (selected) {
            setSelectedValue(selected.selectedLabel || selected.optionLabel);
            onChange(selected.value, type);
        }
        setOpen(false);
    };

    const getMainSelect = () => {
        switch (type) {
            case 'SELECT_1':
                return (
                    <>
                        <div className='select-type-1' onClick={() => setOpen((prev) => !prev)}>
                            <div className='selected-values'>Select an Option</div>
                            <NexusIcon className='expand-icon' src={open ? EXPAND_LESS : EXPAND_MORE} />
                        </div>
                    </>
                );
            case 'SELECT_2':
                return (
                    <div className='select-type-2'>
                        <div onClick={() => setOpen((prev) => !prev)}>{displayElement}</div>
                        <div className='selected-values'>
                            {selectedValues.length
                                ? selectedValues.map((item) => (
                                      <div className='ellipsis' key={item?.id}>
                                          <span>{item?.selectedLabel || item?.optionLabel}</span>
                                          {closeIcon && (
                                              <span onClick={() => handleCloseClick(item, item?.label)}>
                                                  {closeIcon}
                                              </span>
                                          )}
                                      </div>
                                  ))
                                : 'Select an Option'}
                        </div>
                    </div>
                );
            case 'SELECT_3':
                return (
                    <div className='select-type-3' onClick={() => setOpen((prev) => !prev)}>
                        <div className='selected-values'>{selectedValue ? selectedValue : 'Select an Option'}</div>
                        <NexusIcon className='expand-icon' src={open ? EXPAND_LESS : EXPAND_MORE} />
                    </div>
                );
            default:
                return (
                    <div className='select-type-1' onClick={() => setOpen((prev) => !prev)}>
                        <div className='selected-values'>
                            {selectedValues.length
                                ? selectedValues.map((item) => (
                                      <div className='ellipsis' key={item?.id}>
                                          {item?.optionLabel}
                                      </div>
                                  ))
                                : 'Please select an Option'}
                            <NexusIcon className='expand-icon' src={open ? EXPAND_LESS : EXPAND_MORE} />
                        </div>
                    </div>
                );
        }
    };

    return (
        <div className={`custom-select-${className}`}>
            {getMainSelect()}
            {open && (
                <div className='options'>
                    {data?.map((item: any) => {
                        return (
                            <div
                                onClick={() => {
                                    type === 'SELECT_3'
                                        ? onSingleSelect(item, item?.label)
                                        : onMultipleSelect(item, item?.label);
                                }}
                                style={{
                                    backgroundColor: selectedValues?.some((selected) => selected?.id === item?.id)
                                        ? 'lightgray'
                                        : 'transparent',
                                }}
                                key={item?.id}
                            >
                                <div> {item?.optionLabel}</div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default React.memo(CustomSelect);
