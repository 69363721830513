export const regionData = [
    {
        id: 1,
        value: 'us_east',
        label: 'US East',
    },
    {
        id: 2,
        value: 'us_west',
        label: 'US West',
    },
    {
        id: 3,
        value: 'us_central',
        label: 'US Central',
    },
    {
        id: 4,
        value: 'mexico',
        label: 'Mexico',
    },
];

export const lineOfBusinessData = [
    {
        id: 1,
        value: 'consumer_banking',
        label: 'Consumer Banking',
    },
    {
        id: 2,
        value: 'consumer_rank',
        label: 'Consumer Rank',
    },
    {
        id: 3,
        value: 'global_function',
        label: 'Global Function',
    },
    {
        id: 4,
        value: 'institutional_operators',
        label: 'Institutional Operators',
    },
];

export const sectorData = [
    {
        id: 1,
        value: 'atm',
        label: 'ATM',
    },
    {
        id: 2,
        value: 'risk',
        label: 'Risk',
    },
    {
        id: 3,
        value: 'capital_markets',
        label: 'Capital Markets',
    },
    {
        id: 4,
        value: 'institutional_banking',
        label: 'Institutional Banking',
    },
];

export const businessData = [
    {
        id: 1,
        value: 'analytics',
        label: 'Analytics',
    },
    {
        id: 2,
        value: 'business_operations',
        label: 'Business Operations',
    },
    {
        id: 3,
        value: 'business_regulatory',
        label: 'Business Regulatory',
    },
    {
        id: 4,
        value: 'cards_aml',
        label: 'Cards AML',
    },
];
