import './profile.scss';

import { retrieveUserName, selectAuthEmail, selectRole } from '../../../auth';

import React from 'react';
import Table from '../../components/nds/table/table';
import i18n from 'locales/i18n';
import img from './../../assets/images/male-user-profile.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProfilePage = () => {
    const email = useSelector(selectAuthEmail);
    const fullName = useSelector(retrieveUserName);
    const role = useSelector(selectRole);
    const roleNames = role.map((item: any) => item.name).join(', ');

    let profileData = [{ data: ['Name ', fullName] }, { data: ['E-mail ', email] }, { data: ['User Role', roleNames] }];

    const navigate = useNavigate();
    const dashboardPath = '/dashboard';
    const goToDashboard = () => navigate(dashboardPath);

    return (
        <div className='nexus-container nexus-body'>
            <div className='nexus-row'>
                <div className='nexus-col-sm-1 nexus-col-sm-offset-1 nexus-col-md-offset-2 nexus-col-md-2'>
                    <img className='profile-profile-pic' src={img} alt={fullName} />
                </div>
                <div className='nexus-col-sm-4 nexus-col-md-4 table-bg'>
                    <div onClick={() => goToDashboard()} className='go-back nexus-subtitle-sm nexus-my-2'>
                        &larr; &nbsp; {i18n.t<string>('userProfileView.goToDashboard')}{' '}
                    </div>
                    <div className='nexus-h3'>{fullName}</div>

                    <Table column={[]} row={profileData}></Table>
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;
