/* eslint-disable sort-keys */
/* eslint-disable max-len */
export const mockData = {
    auth: {
        accessToken:
            'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IkwxS2ZLRklfam5YYndXYzIyeFp4dzFzVUhIMCIsImtpZCI6IkwxS2ZLRklfam5YYndXYzIyeFp4dzFzVUhIMCJ9.eyJhdWQiOiJkOTA0NGMxNy1lNjAwLTQ1NjAtOWNlMi0xYmY1YTZiYjNiNmYiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC81Yjk3M2Y5OS03N2RmLTRiZWItYjI3ZC1hYTBjNzBiODQ4MmMvIiwiaWF0IjoxNzE2Nzg2MzA2LCJuYmYiOjE3MTY3ODYzMDYsImV4cCI6MTcxNjc5MDg3OSwiYWNyIjoiMSIsImFpbyI6IkFUUUF5LzhXQUFBQVJLYURVSmxxelZ2ZUZpemFzUXZkT255Y052dzMyQ3lFY0RNMWJvdXdRZE9SakVHZS9IRUMwdTJSbUJQNnlVUzMiLCJhbXIiOlsicHdkIiwicnNhIl0sImFwcGlkIjoiZDkwNDRjMTctZTYwMC00NTYwLTljZTItMWJmNWE2YmIzYjZmIiwiYXBwaWRhY3IiOiIwIiwiZGV2aWNlaWQiOiJjMzI2YjM3ZS02NGMzLTRmM2ItOGZlZS04MGY4NGI5MWZhNmYiLCJmYW1pbHlfbmFtZSI6IkFnYXJ3YWwiLCJnaXZlbl9uYW1lIjoiVWRheSIsImlwYWRkciI6IjEzNi4yMjYuMjUwLjkyIiwibmFtZSI6IlVkYXkgQWdhcndhbCIsIm9pZCI6IjI3YjRlMDMwLWE3OTEtNGUxYy04MTk2LWUzNmRmMWU5ZGQyMyIsIm9ucHJlbV9zaWQiOiJTLTEtNS0yMS0xNjQ0NDkxOTM3LTEyNzUyMTAwNzEtMTQxNzAwMTMzMy03NjIxMDEiLCJyaCI6IjAuQVNFQW1ULVhXOTkzNjB1eWZhb01jTGhJTEJkTUJOa0E1bUJGbk9JYjlhYTdPMjhoQUZ3LiIsInNjcCI6IlVzZXIuUmVhZCBVc2VyLlJlYWRCYXNpYy5BbGwiLCJzdWIiOiJiT201bko2OTg1ak80R2dYOVR6T01tSnZYRW9jN1NYbm1FTWN6N1VOZENRIiwidGlkIjoiNWI5NzNmOTktNzdkZi00YmViLWIyN2QtYWEwYzcwYjg0ODJjIiwidW5pcXVlX25hbWUiOiJVZGF5LkFnYXJ3YWxAZ2RzLmV5LmNvbSIsInVwbiI6IlVkYXkuQWdhcndhbEBnZHMuZXkuY29tIiwidXRpIjoiVy03SGdtSml2RWlHMjZOZWN2WmtBQSIsInZlciI6IjEuMCJ9.NNo2ONDGPfIrLL_m0pnfH5zaK10SiEVC1DkR2H8z_j48HJi9unOQQelz4Zys6FbZxEM0FUpxfPklvN2xw4K3UzmzyhIE4aLf9RCeQnCdE_cthqmUbossstuUlkPpctQ-LC5hJzEU8arF2oizp9hGy7W2_uAwJlfoFbMlWlJ0q7lSxjnP0x9u7P5LHuJiqM9W7PBiogWdd4qS_IljKb71L7iluXTGpO0XUnUIgcwUlOPxCqu8nohWvt9sTNj1lq8ZksQavOzJq6oeT9dOjW8UVUf9zYi_H3fMhmiwHVIN7jW6P7j5Q7-ZoDV7YFCdvrcfdfAEoZr2xVcHJtakTcR3_A',
        disableAuthRefresh: false,
        email: 'Uday.Agarwal@gds.ey.com',
        fullName: 'Uday Agarwal',
        idToken: null,
        isAuthenticated: true,
        loading: true,
        logoutPending: false,
        refreshToken: null,
        tokenExpiration: 1716790878580,
        tokenRefreshPending: true,
        userId: '9b4184d7-3b09-4518-975c-c7ceae1b21ea',
        userRole: 'CM-Analyst',
    },
};

export const manualControls = [
    {
        'controlId': 'US.FA.1.C01',
        'name': 'Capital Expenditure is approved.',
        'description':
            "Capital Project forms are completed for all Capital Projects and approved by executive management and/or CFO per Company's DOA policy and reviewed on a periodic basis.  The FA Accountant capitalizes CIP assets that relate to new store openings to begin depreciating. For closing stores listed, the FA Accountant appropriately disposes of all assets related to the closed store.",
        'effectiveness': 'Effective',
        'type': 'Detective',
        'score': '',
        'businessUnit': 'Capital Acquistions',
        'automated/manual': 'Manual',
    },
    {
        'controlId': 'US.FA.1.C02',
        'name': 'Fixed Asset purchase requisitions are properly restricted.',
        'description':
            'When AP or Purchasing attempts to requisition a PO amount that is above the budget, SAP displays a screen stating the amount exceeds the budget and will not allow the PO to be entered.',
        'effectiveness': 'Effective',
        'type': 'Preventative',
        'score': '',
        'businessUnit': 'Capital Acquistions',
        'automated/manual': 'Manual',
    },
    {
        'controlId': 'US.RR.3.C12',
        'name': 'For Direct Shipments, once AP processes the vendor invoice, the invoice data will be sent to the customer automatically.',
        'description':
            'For Direct Shipments, once AP processes the vendor invoice, the invoice data will be sent to the customer automatically.',
        'effectiveness': 'Ineffective',
        'type': 'Preventative',
        'score': '',
        'businessUnit': 'Invoicing Processing',
        'automated/manual': 'Manual',
    },
    {
        'controlId': 'AS.RR.3.C12',
        'name': 'For Direct Shipments, once AP processes the vendor invoice, the invoice data will be sent to the customer automatically.',
        'description':
            'For Direct Shipments, once AP processes the vendor invoice, the invoice data will be sent to the customer automatically.',
        'effectiveness': 'Ineffective',
        'type': 'Preventative',
        'score': '',
        'businessUnit': 'Invoicing Processing',
        'automated/manual': 'Manual',
    },
    {
        'controlId': 'EU.RR.3.C12',
        'name': 'For Direct Shipments, once AP processes the vendor invoice, the invoice data will be sent to the customer automatically.',
        'description':
            'For Direct Shipments, once AP processes the vendor invoice, the invoice data will be sent to the customer automatically.',
        'effectiveness': 'Effective',
        'type': 'Preventative',
        'score': '',
        'businessUnit': 'Invoicing Processing',
        'automated/manual': 'Manual',
    },
];

export const duplicateControls = [
    {
        ref_prc_id: 'COR.PR.AC.C01',
        controlID: 'CTR_1',
        ref_prc_description:
            'In addition to users IDs and passwords, users are authenticated via a two-factor mechanism. Keys are managed per user or per group of users. update for GAM',
        ref_business_unit: 'Access Control',
        tgt_prc_id: 'JP.PR.AC.C01',
        tgt_prc_description:
            'In addition to users IDs and passwords, users are authenticated via a two-factor mechanism. Keys are managed per user or per group of users. update for GAM',
        tgt_business_unit: 'Access Control',
        similarity_score: '1.00',
        similarity_category: 'Duplicate',
        similarity_BU: '1',
        risk_rating: 'Medium',
    },
    {
        ref_prc_id: 'US.FR.1.C03',
        controlID: 'CTR_2',
        ref_prc_description:
            "Monthly, all balance sheet accounts are reconciled and subsequently reviewed and approved by the Accounting Team.  The Sr. Director of Finance determines which members of the Accounting team reconciles, reviews and approves each recon. At a minimum, all balance sheet reconciliations, other than certain low risk 'tier 3' accounts are approved by the Accounting Manager or higher. Unusual items and unreconciled differences are identified and action plans documented prior to the end of the subsequent month.",
        ref_business_unit: 'Account Reconciliation and Analysis',
        tgt_prc_id: 'EU.FR.1.C03',
        tgt_prc_description:
            "Monthly, all balance sheet accounts are reconciled and subsequently reviewed and approved by the Accounting Team.  The Sr. Director of Finance determines which members of the Accounting team reconciles, reviews and approves each recon. At a minimum, all balance sheet reconciliations, other than certain low risk 'tier 3' accounts are approved by the Accounting Manager or higher. Unusual items and unreconciled differences are identified and action plans documented prior to the end of the subsequent month.",
        tgt_business_unit: 'Account Reconciliation and Analysis',
        similarity_score: '1.00',
        similarity_category: 'Duplicate',
        similarity_BU: '1',
        risk_rating: 'Medium',
    },
    {
        ref_prc_id: 'US.FR.1.C03',
        controlID: 'CTR_3',
        ref_prc_description:
            "Monthly, all balance sheet accounts are reconciled and subsequently reviewed and approved by the Accounting Team.  The Sr. Director of Finance determines which members of the Accounting team reconciles, reviews and approves each recon. At a minimum, all balance sheet reconciliations, other than certain low risk 'tier 3' accounts are approved by the Accounting Manager or higher. Unusual items and unreconciled differences are identified and action plans documented prior to the end of the subsequent month.",
        ref_business_unit: 'Account Reconciliation and Analysis',
        tgt_prc_id: 'CN.FR.1.C03',
        tgt_prc_description:
            "Monthly, all balance sheet accounts are reconciled and subsequently reviewed and approved by the Accounting Team.  The Sr. Director of Finance determines which members of the Accounting team reconciles, reviews and approves each recon. At a minimum, all balance sheet reconciliations, other than certain low risk 'tier 3' accounts are approved by the Accounting Manager or higher. Unusual items and unreconciled differences are identified and action plans documented prior to the end of the subsequent month.",
        tgt_business_unit: 'Account Reconciliation and Analysis',
        similarity_score: '1.00',
        similarity_category: 'Duplicate',
        similarity_BU: '1',
        risk_rating: 'Medium',
    },
    {
        ref_prc_id: 'US.FR.2.C06',
        controlID: 'CTR_4',
        ref_prc_description:
            'Monthly, the Sr. Director of Finance reviews the P&L and Balance Sheet flux analysis. Any P&L variance greater than $250K and Balance Sheet variance greater than $750K are investigated and resolved (or explained).',
        ref_business_unit: 'Budgeting',
        tgt_prc_id: 'CN.FR.2.C06',
        tgt_prc_description:
            'Monthly, the Sr. Director of Finance reviews the P&L and Balance Sheet flux analysis. Any P&L variance greater than $250K and Balance Sheet variance greater than $750K are investigated and resolved (or explained).',
        tgt_business_unit: 'Budgeting',
        similarity_score: '1.00',
        similarity_category: 'Duplicate',
        similarity_BU: '1',
        risk_rating: 'Low',
    },
    {
        ref_prc_id: 'US.FR.2.C06',
        controlID: 'CTR_5',
        ref_prc_description:
            'Monthly, the Sr. Director of Finance reviews the P&L and Balance Sheet flux analysis. Any P&L variance greater than $250K and Balance Sheet variance greater than $750K are investigated and resolved (or explained).',
        ref_business_unit: 'Budgeting',
        tgt_prc_id: 'EU.FR.2.C06',
        tgt_prc_description:
            'Monthly, the Sr. Director of Finance reviews the P&L and Balance Sheet flux analysis. Any P&L variance greater than $250K and Balance Sheet variance greater than $750K are investigated and resolved (or explained).',
        tgt_business_unit: 'Budgeting',
        similarity_score: '1.00',
        similarity_category: 'Duplicate',
        similarity_BU: '1',
        risk_rating: 'Low',
    },
    {
        ref_prc_id: 'EU.FR.2.C08',
        controlID: 'CTR_6',
        ref_prc_description:
            'Monthly, the Director of Accounting or Accounting Manager approves reconciliation of budget and forecast (if reported on that period) between Longview and BPC for product P75 to ensure variance-to-actual calculations are consistent between BPC and Longview.',
        ref_business_unit: 'Budgeting',
        tgt_prc_id: 'US.FR.2.C08',
        tgt_prc_description:
            'Monthly, the Director of Accounting or Accounting Manager approves reconciliation of budget and forecast (if reported on that period) between Longview and BPC for product P75 to ensure variance-to-actual calculations are consistent between BPC and Longview.',
        tgt_business_unit: 'Budgeting',
        similarity_score: '1.00',
        similarity_category: 'Duplicate',
        similarity_BU: '1',
        risk_rating: 'Low',
    },
    {
        ref_prc_id: 'US.RR.7.C02',
        controlID: 'CTR_7',
        ref_prc_description:
            'Monthly, the AR Allowance analysis is prepared by Accounting and reviewed by the Sr. Director of Finance or Accounting Manager.',
        ref_business_unit: 'Invoicing Processing',
        tgt_prc_id: 'EU.RR.7.C02',
        tgt_prc_description:
            'Monthly, the AR Allowance analysis is prepared by Accounting and reviewed by the Sr. Director of Finance or Accounting Manager.',
        tgt_business_unit: 'Invoicing Processing',
        similarity_score: '1.00',
        similarity_category: 'Duplicate',
        similarity_BU: '1',
        risk_rating: 'High',
    },
    {
        ref_prc_id: 'EU.RR.7.C02',
        controlID: 'CTR_8',
        ref_prc_description:
            'Monthly, the AR Allowance analysis is prepared by Accounting and reviewed by the Sr. Director of Finance or Accounting Manager.',
        ref_business_unit: 'Invoicing Processing',
        tgt_prc_id: 'US.RR.7.C02',
        tgt_prc_description:
            'Monthly, the AR Allowance analysis is prepared by Accounting and reviewed by the Sr. Director of Finance or Accounting Manager.',
        tgt_business_unit: 'Invoicing Processing',
        similarity_score: '1.00',
        similarity_category: 'Duplicate',
        similarity_BU: '1',
        risk_rating: 'High',
    },
];
