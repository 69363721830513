import { NexusMenu } from '@nexus/react';
import FilterComponent from '../filterComponent';

interface MenuComponentProps {
    position: 'left' | 'right' | undefined;
    show: boolean;
    setShow: (show: boolean) => void;
    attrId: string;
}

const MenuComponent: React.FC<MenuComponentProps> = ({ position, show, attrId, setShow }) => {
    return (
        <NexusMenu
            position={position}
            data-testid='btn-close'
            open={show}
            onCloseEvent={(e: any) => {
                e.preventDefault();
                setShow(false);
            }}
            attrId={attrId}
            className='menu-component'
        >
            <FilterComponent />
        </NexusMenu>
    );
};

export default MenuComponent;
