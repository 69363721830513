import './App.scss';

import store, { persistor } from '../store';

import AppRoutes from './routes';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { setNonce } from '@nexus/core/loader';
setNonce('07062024');

const App: React.FC = () => {
    return (
        <div data-testid='nexus-app'>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <AppRoutes />
                </PersistGate>
            </Provider>
        </div>
    );
};

export default App;
