import './component.scss';

function SingleLabelComponent({
    label,
    value,
    type,
    className = '',
}: {
    label: string;
    value: any;
    type?: string;
    className?: string;
}) {
    const getValue = () => {
        switch (type) {
            case 'textarea':
                return <textarea value={value} className='textarea' />;
            default:
                return value;
        }
    };
    return (
        <div className={'label-value ' + className}>
            <div className='label'>{label}</div>
            <div className='value'>{getValue()}</div>
        </div>
    );
}

export default SingleLabelComponent;
