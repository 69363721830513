/* eslint-disable no-console */
/* eslint-disable sort-keys */
import OktaAuth from '@okta/okta-auth-js';
import { IOktaAuthProps, IoktaAuthProps } from '../authProviderTypes';
import { REACT_APP_CLIENT_ID as clientId, REACT_APP_OAUTH_BASE_URL as authority } from '../../config/settings';

export const oktaAuthenticate = async ({ callBack }: IoktaAuthProps) => {
    const authClient: OktaAuth = new OktaAuth({
        clientId,
        issuer: authority,
        redirectUri: window.location.origin,
        tokenManager: {
            storage: 'sessionStorage', // Use sessionStorage for token storage
        },
    });
    const tokenParams: any = {
        scopes: ['openid', 'email', 'profile'],
    };
    try {
        const token: any = await authClient.token.getWithPopup(tokenParams);
        callBack &&
            callBack({
                accessToken: token.tokens.accessToken.accessToken,
                expiresOn: token.tokens.accessToken.expiresAt,
                name: token.tokens.idToken.claims.name,
                email: token.tokens.idToken.claims.email,
                idToken: token.tokens.idToken.idToken,
            });
        return token;
    } catch (e) {
        console.log(e);
    }
};

export const authenticateSilently = async ({ callBack }: IOktaAuthProps) => {
    const authClient: OktaAuth = new OktaAuth({
        clientId: clientId,
        issuer: authority,
        redirectUri: window.location.origin,
    });
    const token: any = await authClient.token.getWithoutPrompt();
    callBack &&
        callBack({
            accessToken: token.tokens.accessToken.accessToken,
            expiresOn: Number(token.tokens.accessToken.expiresAt),
        });
};

export const oktaLogout = async ({ callBack }: IoktaAuthProps) => {
    const authClient: OktaAuth = new OktaAuth({
        clientId: clientId,
        issuer: authority,
        redirectUri: window.location.origin,
    });
    await authClient.signOut();
    callBack && callBack();
};
