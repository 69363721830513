import './modal.scss';

import { NexusModal, NexusModalBody, NexusModalFooter, NexusModalHeader, NexusModalHeaderSubtitle } from '@nexus/react';
import React, { useEffect, useState } from 'react';

interface InputProps {
    fullscreen?: boolean;
    show: boolean;
    size?: 'full' | 'lg' | 'md' | 'xl' | 'xs' | 'sm';
    header: React.ReactNode;
    children?: React.ReactNode;
    closeModal: () => void;
    type?: string;
    handleSubmitButton?: () => void;
    disableSubmitButton?: string;
    extraClass?: string;
    footer?: React.ReactNode;
}

const ModalComponent: React.FC<InputProps> = ({
    children,
    fullscreen,
    show,
    size,
    header,
    closeModal,
    extraClass,
    footer,
}) => {
    const [isshow, setShow] = useState<boolean>(show);

    useEffect(() => {
        setShow(show);
    }, [show]);

    const onCloseModal = () => {
        setShow(false);
        closeModal();
    };

    return (
        <div className='modal-styles'>
            <NexusModal
                data-testid='overlay'
                id='modal'
                className='nexus-modal-styles'
                show={isshow}
                fullscreen={fullscreen}
                size={size}
            >
                <NexusModalHeader data-testid='modal-header' onCloseEvent={onCloseModal} className={extraClass}>
                    {header}
                    <NexusModalHeaderSubtitle data-testid='modal-Placeholder'></NexusModalHeaderSubtitle>
                </NexusModalHeader>
                <NexusModalBody>{children}</NexusModalBody>
                <NexusModalFooter>{footer}</NexusModalFooter>
            </NexusModal>
        </div>
    );
};

export default ModalComponent;
