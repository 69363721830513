import './loginPage.scss';

import React, { useEffect } from 'react';
import { decodeIdToken, formatTokenData, getDataFromUri } from '../../../auth/authHelper';
import { getUserDetails, selectAuthToken, setAuthToken, setLoading } from '../../../auth';
import { useDispatch, useSelector } from 'react-redux';

import Login from '../../components/login/login';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const authToken: string = useSelector(selectAuthToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const getRoles = async () => {
        dispatch(getUserDetails());
    };
    useEffect(() => {
        getDataFromUri(dispatch, getRoles);
    }, []);

    useEffect(() => {
        if (authToken) {
            setTimeout(() => {
                navigate('/dashboard');
            }, 2000);
        }
    }, [authToken]);

    const handleLogin = async (data: any) => {
        if (data.accessToken) {
            const tokenData = formatTokenData(data.accessToken, Number(data.expiresOn));
            await dispatch(setAuthToken(tokenData));
            decodeIdToken(data.idToken, dispatch);
            await getRoles();
            dispatch(setLoading(false));
            navigate('/dashboard');
        }
    };

    return <Login onLogin={handleLogin} />;
};
export default LoginPage;
