import { AppDispatch } from '../store/index';
import { AxiosRequestConfig } from 'axios';
import { refreshAuthToken } from '../auth/authSlice';

/**
 * Returns a value from a uri hash (window.location.hash) or search (window.location.search) string.
 * Assumes first character should be discarded such as the '#' or '?'
 * @param {*} key
 * @param {*} hash
 */
export const getValueFromSearchString = (key: string, searchString: string) => getValue(key, searchString.substring(1));

/**
 * Returns a value from a string that contains key=value pairs separated by a provided or default '&' separator.
 * @param {*} key
 * @param {*} hash
 */
export const getValue = (key: string, searchString: string, separator = '&') => {
    // Splits the elements in the string into a token by searching for '&', then extracts key/value item pairs to
    // determine a match
    const valueArray = searchString
        .split(separator)
        .map((token) => token.split('='))
        .find((item) => item[0] === key);

    return valueArray ? decodeURIComponent(valueArray[1]) : null;
};

// Utility for formatting the request/headers with the access token
export const formatRequestWithToken = (req: AxiosRequestConfig<any>, accessToken: any) => ({
    ...req,
    headers: accessToken ? { ...req.headers, Authorization: `Bearer ${accessToken}` } : req.headers || {},
});

/**
 * Refresh access token by dispatching a request to do so then waiting for refresh to complete.
 * @param {} dispatch
 * @param {*} getState
 */
export const refreshToken = async (dispatch: AppDispatch) => {
    // Trigger refresh in auth state for any components that are interested which will set
    // refresh to pending
    dispatch(refreshAuthToken());

    // provide a reasonable time (1 second) to wait for listeners to react to the refresh action since there's
    // no event to subscribe to that would indicate a listener has completed its refresh task
    await new Promise((resolve) => setTimeout(resolve, 1000));
};
